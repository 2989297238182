import React from 'react';
import { Input, Dropdown, Space, Image, Divider, Button, Select } from 'antd';
import { FaRegThumbsUp } from '@react-icons/all-files/fa/FaRegThumbsUp';
import { FaChevronDown } from '@react-icons/all-files/fa/FaChevronDown';
import { FaPlayCircle } from '@react-icons/all-files/fa/FaPlayCircle';
import './StudentLifeNew.scss';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { fetchUniversityResourceById, getUniversityResourceSelector } from '../../../MainScreen/MainScreenSlice';
import { clearAddLikeToBuddy, getAllStudentFeedsRequest, getStudentLifeSelector } from './StudentLifeSlice';
import { useEffect, useState } from 'react';
import { getBuddyListSelector } from '../Home/PeopleTab/PeopleTabSlice';
import { useLocation } from 'react-router-dom';
import { getHost } from '../../../utils/hostDataFunction';

const items: any = [
  {
    label: <a href="#">1st menu item</a>,
    key: '0',
  },
  {
    label: <a href="#">2nd menu item</a>,
    key: '1',
  },
  {
    type: 'divider',
  },
  {
    label: '3rd menu item',
    key: '3',
  },
];

export default function StudentLifePage() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { payload, status: payloadStatus } = useAppSelector(getUniversityResourceSelector);
  const { getAllFeedsStatus, isFetchingAllFeeds, getAllFeedsResponse, addFeedLikeStatus } =
    useAppSelector(getStudentLifeSelector);
  const { isFetching, status, buddyListPayload } = useAppSelector(getBuddyListSelector);
  const [originalMainPosts, setOriginalMainPosts] = useState<any>([]);
  const [mainPosts, setMainPosts] = useState<any>([]);
  const [popularPosts, setPopularPosts] = useState([]);

  const [enableWordpress, setEnableWordPress] = useState(false);

  const searchParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    const studentLife = searchParams.get('studentLife');
    const wordPress = searchParams.get('wordpress');

    if (studentLife && wordPress) {
      setEnableWordPress(true);
    }

    if (payload) {
      const uniId = payload?.domain;
      dispatch(getAllStudentFeedsRequest({ uniId }));
    } else {
      const host = getHost();

      if (host) {
        dispatch(fetchUniversityResourceById(host));
      }
    }
  }, []);

  useEffect(() => {
    if (payloadStatus === 'success') {
      const uniId: any = payload?.domain;
      dispatch(getAllStudentFeedsRequest({ uniId }));
    }
  }, [payloadStatus]);

  useEffect(() => {
    if (addFeedLikeStatus === 'SUCCESS') {
      const uniId: any = payload?.domain;
      dispatch(getAllStudentFeedsRequest({ uniId }));
      dispatch(clearAddLikeToBuddy());
    }
  }, [addFeedLikeStatus]);

  useEffect(() => {
    if (getAllFeedsStatus === 'SUCCESS') {
      handlePopularPosts(getAllFeedsResponse);
      setMainPosts(getAllFeedsResponse);
      setOriginalMainPosts(getAllFeedsResponse);
    }
  }, [getAllFeedsStatus]);

  const handlePopularPosts = (data: any) => {
    const objectWithHighestLikeCount = data.reduce((prev: any, current: any) => {
      return current.likeCount > prev.likeCount ? current : prev;
    }, data[0]);

    const highestLikeCount = objectWithHighestLikeCount && objectWithHighestLikeCount?.likeCount;

    const filteredPosts = data?.filter((el: any) => {
      return el?.likeCount === highestLikeCount;
    });
    setPopularPosts(filteredPosts);
  };

  const stripHtmlTags = (html: any) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (typeof e.target.value === 'string') {
      const searchResults = originalMainPosts.filter((item: any) =>
        stripHtmlTags(item.textBody).toLowerCase().includes(e.target.value.toLowerCase())
      );

      setMainPosts(searchResults.length > 0 ? searchResults : originalMainPosts);
    }
  };

  const handleFilter = (event: any) => {
    let sortedArr: any = [];
    if (event === 'recent') {
      sortedArr = [...mainPosts].sort((a: any, b: any) => {
        const dateA: any = new Date(a?.created);
        const dateB: any = new Date(b?.created);
        return dateB - dateA;
      });
    } else if (event === 'popular') {
      sortedArr = [...mainPosts].sort((a: any, b: any) => b.likeCount - a.likeCount);
    }

    setMainPosts(sortedArr);
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="left-panel">
          <div className="search-panel">
            <Input size="large" placeholder="Search posts" className="search-input" onChange={handleSearch} />
            <Select
              defaultValue="recent"
              onChange={handleFilter}
              options={[
                { value: 'recent', label: 'Most Recent' },
                { value: 'popular', label: 'Most Popular' },
              ]}
              className="recent-drop"
            />
            {/* <Dropdown
              menu={{
                items,
              }}
              trigger={["click"]}
              className="recent-drop"
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  <h3 className="recent-drop-down-text">Most recent</h3>
                  <FaChevronDown className="recent-drop-icon" />
                </Space>
              </a>
            </Dropdown> */}
          </div>

          <div className="scrollable">
            <div className="feed-card-big">
              <div className="top">
                <Image
                  className="user_image"
                  width={60}
                  src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                />
                <div className="name-section">
                  <h3 className="buddy-name">Paul</h3>
                  <p className="time">5 mins ago</p>
                </div>
              </div>
              <div className="middle">
                <div className="image-grid-5">
                  <img
                    src="https://images.pexels.com/photos/18844140/pexels-photo-18844140/free-photo-of-building-of-natwest-bank-in-llandudno.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    className="image-feed image-grid-5-col-2 image-grid-5-row-2"
                  />
                  <img
                    src="https://images.pexels.com/photos/2305098/pexels-photo-2305098.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    className="image-feed"
                  />
                  <img
                    src="https://images.pexels.com/photos/3952080/pexels-photo-3952080.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    className="image-feed"
                  />
                  <img
                    src="https://images.pexels.com/photos/5554236/pexels-photo-5554236.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    className="image-feed"
                  />
                  <span className="video-feed">
                    <video
                      src="https://univiserstg.blob.core.windows.net/videos/file_example_MP4_480_1_5MG-1693882230110.mp4"
                      className="image-feed"
                    ></video>
                    <FaPlayCircle className="play-icon" />
                  </span>
                </div>
                {/* <div className='image-grid-4'>
                                    <span className='video-feed image-grid-4-col-2 image-grid-4-row-1'>
                                        <video src="https://univiserstg.blob.core.windows.net/videos/file_example_MP4_480_1_5MG-1693882230110.mp4" className='image-feed'></video>
                                        <FaPlayCircle className='play-icon' />
                                    </span>
                                    <img src="https://images.pexels.com/photos/7973205/pexels-photo-7973205.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className='image-feed image-grid-4-col-2 image-grid-4-row-1' />
                                    <img src="https://images.pexels.com/photos/1106468/pexels-photo-1106468.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className='image-feed image-grid-4-col-2 image-grid-4-row-1' />
                                    <img src="https://images.pexels.com/photos/256490/pexels-photo-256490.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className='image-feed image-grid-4-col-2 image-grid-4-row-1' />
                                </div> */}
                {/* <div className='image-grid-3'>
                                    <span className='video-feed image-grid-3-col-4'>
                                        <video src="https://media.w3.org/2010/05/sintel/trailer.mp4#t=10" className='image-feed'></video>
                                        <FaPlayCircle className='play-icon' />
                                    </span>
                                    <img src="https://images.pexels.com/photos/159490/yale-university-landscape-universities-schools-159490.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className='image-feed image-grid-3-row-2 image-grid-3-col-2' />
                                    <img src="https://images.pexels.com/photos/159775/library-la-trobe-study-students-159775.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className='image-feed image-grid-3-row-2 image-grid-3-col-2' />
                                </div> */}
                {/* <div className='image-grid-2'>
                                    <span className='video-feed image-grid-4-col-2 image-grid-4-row-1'>
                                        <video src="https://media.w3.org/2010/05/sintel/trailer.mp4#t=10" className='image-feed'></video>
                                        <FaPlayCircle className='play-icon' />
                                    </span>
                                    <img src="https://www.w3schools.com/w3images/ocean.jpg" className='image-feed image-grid-4-col-2 image-grid-4-row-1' />
                                </div> */}
                {/* <div className='image-grid-1'>
                                    <span className='video-feed image-grid-4-col-1 image-grid-4-row-1'>
                                        <video src="https://media.w3.org/2010/05/sintel/trailer.mp4#t=10" className='image-feed'></video>
                                        <FaPlayCircle className='play-icon' />
                                    </span>
                                </div> */}
              </div>
              <div className="bottom">
                <p>
                  In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the
                  visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used
                  as a placeholder before final copy is available In publishing and graphic design, Lorem ipsum is a
                  placeholder text commonly used to demonstrate the visual form of a document or a typeface without
                  relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available
                </p>
                <Divider className="bottom-divider" />
                <div className="button-section">
                  <Button
                    type="text"
                    className="button-section-button"
                    icon={<FaRegThumbsUp className="button-icon" />}
                  >
                    Like
                  </Button>
                  <span className="likes-count">45 Likes</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="right-panel">
          <div className="search-panel">
            <h3>Popular posts</h3>
          </div>
          <div className="scrollable">
            <div className="popular-feed-card-big">
              <div className="middle">
                <div className="image-grid-1">
                  <img
                    src="https://www.w3schools.com/w3images/ocean.jpg"
                    className="image-feed image-grid-4-col-1 image-grid-4-row-1"
                  />
                </div>
              </div>
              <div className="bottom">
                <p>In publishing and graphic design, Lorem ipsum is a place...</p>
                <div className="button-section">
                  <Button type="link" className="button-section-button">
                    Read more
                  </Button>
                  <span className="likes-count">45 Likes</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
