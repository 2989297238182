import { Avatar, Button, Modal } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { FaPaperPlane } from 'react-icons/fa';
import './videoPreviewModal.scss';
import { isMobile, isTablet } from 'react-device-detect';
import { useRef } from 'react';

export const VideoPreviewModal = ({
  visibleVideModal,
  handleVideoModalCancel,
  themeColor,
  firstName,
  programme,
  smallProfileUrl,
  introVideoUrl,
  visibleMessagePopup,
}: any) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const handleCloseModal = () => {
    handleVideoModalCancel();
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  return (
    <Modal
      title={null}
      open={visibleVideModal}
      footer={null}
      onCancel={handleCloseModal}
      width={!isMobile ? 800 : isTablet ? 500 : 350}
    >
      <div className="titleVideoModal">
        <Avatar className="profileImage" icon={<UserOutlined />} src={smallProfileUrl} />
        <span className="titleText">
          <h2>Get to know {firstName}, A warm introduction to who I am</h2>
          <h4 className="titleSubText">{programme}</h4>
        </span>
      </div>
      <div>
        <video ref={videoRef} width="100%" height="500px" controls className="videoSection">
          <source src={introVideoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="footer">
        <Button
          type="primary"
          size="large"
          className="questionButton"
          style={{
            backgroundColor: `${themeColor}`,
            borderColor: `${themeColor}`,
          }}
          onClick={visibleMessagePopup}
        >
          <div className="buttonContent">
            <div>
              <FaPaperPlane />
            </div>
            <div className="buttonText">Ask me a question</div>
          </div>
        </Button>
      </div>
    </Modal>
  );
};
