import { getHost } from '../../../../utils/hostDataFunction';

export type FavMessageProps = {
  messageId: number;
  prospectId: number;
  status: boolean;
};

export const switchFavMessage = async (favMessageProps: FavMessageProps) => {
  // let userDetails = JSON.parse(<string>localStorage.getItem("token"));
  // console.log("user",user)
  let sessionDetails = JSON.parse(localStorage.getItem('sessionDetails') as string);
  let uniId = getHost();
  return await fetch(
    `${process.env.REACT_APP_API_URL}/chat/buddy/${favMessageProps.prospectId}/history/${favMessageProps.messageId}/favorite`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        uniId: uniId ? uniId : '',
        session: sessionDetails?.sessionId,
      },
      body: JSON.stringify({
        status: favMessageProps.status,
      }),
    }
  ).then((res) => res.json());
};
