import { getHost } from '../../../../../utils/hostDataFunction';
import { ValidateOTPDTO } from './ValidateOTPSlice';

export const validateOTPRequest = async (otpDTO: ValidateOTPDTO) => {
  let sessionDetails = JSON.parse(localStorage.getItem('sessionDetails') as string);
  let uniId = getHost();

  return await fetch(`${process.env.REACT_APP_API_URL}/chat/prospect/${otpDTO.pid}/verify`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: '*/*',
      uniId: uniId ? uniId : '',
      session: sessionDetails?.sessionId,
    },
    body: JSON.stringify({
      code: otpDTO.code,
    }),
  }).then((res) => res.text());
};
