import { getHost } from '../../../../utils/hostDataFunction';

export type GetBuddlistProps = {
  message: string;
  email: string;
  phone: string;
  name: string;
  channel: 'EMAIL' | 'SMS' | 'APP' | 'WhatsApp';
};

export const getAllChatBuddyListRequest = async (id: any) => {
  // let userDetails = JSON.parse(<string>localStorage.getItem("token"));
  // console.log("user",user)
  let sessionDetails = JSON.parse(localStorage.getItem('sessionDetails') as string);

  let headers: any = {
    'Content-Type': 'application/json',
    uniId: getHost(),
    pubId: id,
  };

  if (sessionDetails?.sessionId) {
    headers.session = sessionDetails?.sessionId;
  }

  return await fetch(`${process.env.REACT_APP_API_URL}/chat/buddy`, {
    method: 'GET',
    headers: headers,
  }).then((res) => res.json());
};
