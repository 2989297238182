import React, { useEffect, useState } from 'react';
import './Dashboard.scss';
import { Col, Layout, Row, Spin, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { getUniversityResourceSelector } from '../../../../MainScreen/MainScreenSlice';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import PeopleTab from '../PeopleTab/PeopleTab';
import { hexatoRGBColorFunction } from '../../../../utils/hexatoRGBColorFunction';
import TabPane from 'antd/lib/tabs/TabPane';
import { StudentLife } from '../../StudentLife/StudentLife';
import { MdDynamicFeed, MdOutlineSupervisedUserCircle } from 'react-icons/md';
import PageLoader from '../../../PageLoader';
import CustomFooter from '../../../common/Footer/Footer';
import { Helmet } from 'react-helmet';

const { Content } = Layout;

function Dashboard() {
  const [idToken, setIdToken] = useState(null);
  const [enableWordPress, setEnableWordPress] = useState<any>(false);
  const [enableBothTabs, setEnableBothTabs] = useState<any>(false);
  const [enableStudentLife, setEnableStudentLife] = useState<boolean>(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const { payload } = useAppSelector(getUniversityResourceSelector);

  useEffect(() => {
    const handleWindowSize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowSize);

    return () => {
      window.removeEventListener('resize', handleWindowSize);
    };
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    const wordPress = searchParams.get('wordpress');
    const studentLife = searchParams.get('studentLife');

    if (wordPress && studentLife) {
      setEnableBothTabs(true);
      setEnableStudentLife(true);
      setEnableWordPress(true);
    } else if (wordPress) {
      setEnableWordPress(true);
    } else if (studentLife) {
      setEnableStudentLife(true);
    }
  }, []);

  const dispatch = useAppDispatch();
  // const { isFetching, status, payload } = useAppSelector(getUniversityResourceSelector);
  const { t, i18n } = useTranslation();
  const {} = i18n;

  const renderTabTitles = (tab: string) => {
    switch (tab) {
      case 'profile':
        return (
          <div className="profileStudentLifeButton">
            <div>
              <MdOutlineSupervisedUserCircle size={35} />
            </div>
            <h4>Profile</h4>
          </div>
        );

      case 'life':
        return (
          <div className="profileStudentLifeButton">
            <div>
              <MdDynamicFeed size={35} />
            </div>
            <h4>Student Life</h4>
          </div>
        );

      default:
        return null;
    }
  };

  const uniDomainName = payload?.domain;

  const isEcusl = uniDomainName === 'ecusl.univiser.io';

  const ecuslClass = enableWordPress && isEcusl ? 'ecuslStyle' : '';

  const iframeClass = enableWordPress && !isEcusl ? 'iframeClass' : '';

  return (
    <div className={`home_page ${iframeClass} ${ecuslClass}`}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{payload?.theme?.shortName}</title>
        <meta property="og:title" content={payload?.theme?.shortName} />
        <meta
          property="og:description"
          content={`Connect with a Student Ambassador from ${payload?.theme?.shortName} and get all your doubts cleared`}
        />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={payload?.theme?.logoUrl} />
        <meta property="og:image:width" content="256" />
        <meta property="og:image:height" content="256" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      ;
      <Content>
        {enableBothTabs && windowWidth >= 600 && (
          <div className="profileFooter">
            Powered by
            <img
              className="univiserLogo"
              src="https://uploads-ssl.webflow.com/6049cc7c5a2ed9ce1141738b/605489a42906a8585acb3e93_Univiser%20logo-01-p-500.png"
              alt="Univiser Logo"
            />
          </div>
        )}
        {/* {isFetching ? (
          <PageLoader />
        ) : (
          <> */}
        {!enableWordPress ? (
          <div
            className="jumbotron bg-jumb bannerMobile"
            style={{
              background: `linear-gradient(79deg, ${hexatoRGBColorFunction(
                payload?.theme?.themeColor || '#fafafa'
              )} 0%, ${hexatoRGBColorFunction(payload?.theme?.secondaryColor || '#fafafa')} 100%)`,
            }}
          >
            <div className={'jumb-container jumb-containerMobile'}>
              <h2 className="bannerText">{payload?.theme?.headerMessage}</h2>
              <Row>
                <Col xs={24} lg={16}>
                  <p className="bannerSubText bannerHeaderSubTextMobile">{payload?.theme?.contentMessage}</p>
                </Col>
              </Row>
            </div>
          </div>
        ) : (
          <></>
        )}
        {/*<div className="bannerHeader bannerHeaderMobile ">*/}
        {/*      {t("dashboard.captionFilter.title")}*/}
        {/*      Start chatting with our...*/}
        {/*  </div>*/}
        {enableWordPress ? (
          <Tabs className={`customTabs customTabContainer `}>
            {enableBothTabs ? (
              <>
                <TabPane tab={renderTabTitles('profile')} key="1">
                  <Layout>
                    <Content>
                      <PeopleTab />
                    </Content>
                  </Layout>
                </TabPane>
                <TabPane tab={renderTabTitles('life')} key="2">
                  <StudentLife />
                </TabPane>
              </>
            ) : (
              <TabPane key="1">
                <Layout>
                  <Content>
                    <PeopleTab />
                  </Content>
                </Layout>
              </TabPane>
            )}
          </Tabs>
        ) : (
          <>{enableStudentLife ? <StudentLife /> : <PeopleTab />}</>
        )}
        {enableBothTabs && windowWidth <= 600 && <CustomFooter />}
      </Content>
    </div>
  );
}

export default Dashboard;
