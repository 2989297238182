import { Avatar, Button, Dropdown, Input, List, Menu, Spin, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import './BuddyList.scss';
import { clearGetAllBuddyList, getAllBuddyChatList, getAllBuddyListSelector } from './BuddyListSlice';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { useSelector } from 'react-redux';
import { ChatBuddyProps } from '../../../Models/ChatBuddyProps';
import { getChatArea } from '../ChatArea/ChatAreaSlice';
import { setBuddyID } from '../SetSelectBuddySlice';
import {
  clearReadChatMessage,
  readChatMessage,
  readChatMessageSelector,
} from '../ChatArea/ReadMessage/ReadMessageSlice';
import { SearchOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { getAllFavMessages, getAllFavMessagesSelector } from '../getAllStartMessages/FavMessageSwitchSlice';
import FavMessageList from '../getAllStartMessages/FavMessageList';
import { useLocation, useParams } from 'react-router-dom';
import { getUniversityResourceSelector } from '../../../../MainScreen/MainScreenSlice';
import { changeTimeToCurrentTimezone } from '../../../../utils/fomatter';
import PageLoader from '../../../PageLoader';
import { isMobile } from 'react-device-detect';

function BuddyList({ onClose, themeColor, selectedChatId, handleChatOpen, setSelectedId, selectedId }: any) {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { id } = useParams();
  // const [selectedId, setSelectedId] = useState<ChatBuddyProps>();
  const { t, i18n } = useTranslation();
  const {} = i18n;
  let { isFetching, buddyList } = useSelector(getAllBuddyListSelector);
  let { isFetching: favListIsFetching } = useSelector(getAllFavMessagesSelector);
  let { status: readMessageStatus } = useSelector(readChatMessageSelector);
  const [filterList, setFilterList] = useState<ChatBuddyProps[]>(buddyList);
  const [search, setSearch] = useState<string>('');
  const [switchFavMessages, setSwitchFavMessages] = useState<boolean>(false);
  const { payload } = useAppSelector(getUniversityResourceSelector);

  useEffect(() => {
    setFilterList(buddyList);
    if (selectedChatId && !selectedId) {
      dispatch(getChatArea({ id: parseInt(selectedChatId), pubId: id }));
      const filteredBuddy = buddyList?.filter((el) => {
        return el?.publicId == selectedChatId;
      });
      // console.log("=== filteredBuddy ===", filteredBuddy);
      dispatch(setBuddyID(filteredBuddy[0]));
    }
  }, [buddyList]);

  useEffect(() => {
    dispatch(getAllBuddyChatList(id));
  }, []);

  // useEffect(()=>{
  //     if(favListStatus ==="SUCCESS"){
  //         setFilterList(favMessageListResp.map(myFunction))
  //     }
  // },[favListStatus])

  useEffect(() => {
    if (selectedId?.publicId) {
      dispatch(getChatArea({ id: parseInt(selectedId.publicId), pubId: id }));
      dispatch(setBuddyID(selectedId));
      dispatch(
        readChatMessage({
          buddyID: parseInt(selectedId.publicId),
          pubId: id,
        })
      );
      onClose(false);
    }
  }, [selectedId]);

  useEffect(() => {
    if (readMessageStatus === 'SUCCESS') {
      dispatch(getAllBuddyChatList(id));
      dispatch(clearGetAllBuddyList());
      dispatch(clearReadChatMessage());
    }
  }, [readMessageStatus]);

  // useEffect(() => {
  //   setFilterList(buddyList);
  // }, [buddyList]);

  useEffect(() => {
    if (buddyList.length > 0) {
      setFilterList(
        buddyList.filter((item: ChatBuddyProps) => item.firstName.toLowerCase().indexOf(search.toLowerCase()) > -1)
      );
    }
  }, [search]);

  function onBuddyClick(item: ChatBuddyProps) {
    // console.log("=== item ===", item);

    setSelectedId(item);
    if (isMobile) {
      dispatch(setBuddyID(item));
      dispatch(getChatArea({ id: parseInt(item?.publicId), pubId: id }));
      dispatch(
        readChatMessage({
          buddyID: parseInt(item?.publicId),
          pubId: id,
        })
      );
    }
    if (handleChatOpen) {
      handleChatOpen();
    }
  }

  function favMessageSWitch() {
    setSwitchFavMessages(!switchFavMessages);
    dispatch(getAllFavMessages());
  }

  // console.log("=== id ===", id);

  return (
    <>
      {!switchFavMessages ? (
        <List className={`buddyListLayoutSider`}>
          <div className="buddiesListHeader">
            <Input
              className="buddiesSearchInput"
              placeholder="Search Ambassadors"
              onChange={(e) => setSearch(e.target.value)}
              prefix={<SearchOutlined />}
            />
            {/* <Dropdown
              overlay={
                <Menu>
                  <Menu.Item key="0">
                    <div onClick={favMessageSWitch} className="dropDownShareButton">
                      {t("Chat.BuddyList.starredDropDownBtn")}
                    </div>
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
              placement="bottomRight"
            >
              <Button className="menuButtonBuddie">
                <BsThreeDotsVertical className="menuIconIn" size={25} />
              </Button>
            </Dropdown> */}
          </div>
          <div className="myChatBuddiesView">
            {filterList && filterList.length > 0 && (
              <List
                className="buddyListBody"
                dataSource={filterList}
                loading={isFetching}
                renderItem={(item: ChatBuddyProps, index) => (
                  <List.Item
                    className={`listLayout ${
                      item?.publicId === (selectedId?.publicId || selectedChatId) ? 'active' : ''
                    }`}
                    // className="listLayout active"
                    style={
                      item?.publicId === (selectedId?.publicId || selectedChatId)
                        ? {
                            backgroundColor: themeColor,
                            color: '#fafafa',
                          }
                        : {}
                    }
                    key={index}
                    onClick={() => onBuddyClick(item)}
                  >
                    <List.Item.Meta
                      className="buddiesInfo"
                      avatar={<Avatar src={item.smallProfileUrl} />}
                      title={<a className="buddieName">{item.firstName}</a>}
                      description={<div className="lastMessagePart">{item.lastMessagePart}</div>}
                    />
                    <div className="timeTagLayout">
                      {item.lastChatDate && (
                        <p className="lastChatDate">
                          {changeTimeToCurrentTimezone(item.lastChatDate, payload?.minOffset)}
                        </p>
                      )}
                      {item?.unreadCount > 0 && <Tag className="unreadCount">{item?.unreadCount}</Tag>}
                    </div>
                  </List.Item>
                )}
              />
            )}
          </div>
        </List>
      ) : (
        <>{favListIsFetching ? <PageLoader /> : <FavMessageList setSwitchFavMessages={setSwitchFavMessages} />}</>
      )}
    </>
  );
}

export default BuddyList;
