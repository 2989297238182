import { Button, Col, Image, Layout, Row, Space, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { TypeOFChat } from '../../Models/ChatAreaProps';
import './ChatBubble.scss';
import { MdReply, MdStarRate } from 'react-icons/md';
import { useAppDispatch } from '../../../hooks';
import { useTranslation } from 'react-i18next';
import { setFavMessage } from './FavMessageSwitch/FavMessageSwitchSlice';
import { changeTimeToCurrentTimezone } from '../../../utils/fomatter';

function ChatBubbleContent({ content, mediaType }: { content: string; mediaType: string }) {
  switch (mediaType) {
    case 'MESSAGE':
      return <>{content}</>;
    case 'DOCUMENT':
      return <a href={content}>{content}</a>;
    case 'IMAGE':
      return <Image src={content}>{content}</Image>;
    default:
      return null;
  }
}

function ChatBubble({
  content,
  time,
  type,
  mediaType,
  id,
  buddyId,
  favoriteMessage,
  themeColor,
  minOffset,
}: {
  favoriteMessage: boolean | undefined;
  buddyId: number;
  id: string;
  content: string;
  time: string;
  type: TypeOFChat;
  mediaType: string;
  themeColor: string;
  minOffset: string | undefined;
}) {
  const [fav, setfav] = useState<boolean | undefined>(favoriteMessage);
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  function switchFavMessage() {
    setfav(!fav);
  }

  useEffect(() => {
    if (fav) {
      dispatch(
        setFavMessage({
          messageId: parseInt(id),
          prospectId: buddyId,
          status: fav,
        })
      );
    }
  }, [fav]);

  return (
    <Row>
      {type === TypeOFChat.P_TO_B ? (
        <Col className="chatBubbleLayout" offset={2}>
          {/* <Button type={"link"} className="replyBtn">
            <MdReply />
            reply
          </Button> */}
          <div className="chatBubble" style={{ background: themeColor }}>
            <ChatBubbleContent mediaType={mediaType} content={content} />
            {/* <Space className="starMsg">
              {!fav ? (
                <MdStarRate className="star" onClick={switchFavMessage} />
              ) : (
                <MdStarRate className="star stared" onClick={switchFavMessage} />
              )}
            </Space> */}
            {/*<Button type="primary" icon={}  />*/}
          </div>
          <div className="chatBubbleTime">{changeTimeToCurrentTimezone(time, minOffset)}</div>
        </Col>
      ) : (
        <Col className="replyUserLayout">
          {/* <Button type={"link"} className="replyBtn">
            <MdReply />
            reply
          </Button> */}
          <div className="chatBubble">
            <ChatBubbleContent mediaType={mediaType} content={content} />
            {/* <Space className="starMsg">
              {!fav ? (
                <MdStarRate className="star" onClick={switchFavMessage} />
              ) : (
                <MdStarRate className="star stared" onClick={switchFavMessage} />
              )}
            </Space> */}
            {/*<Button type="primary" icon={}  />*/}
          </div>
          <div className="chatBubbleTime">{changeTimeToCurrentTimezone(time, minOffset)}</div>
          {/* <div className='replyUser'>
                        <Row>
                            <Col offset={20}> <Button type={'link'}> Reply</Button></Col>
                        </Row>
                        <p>{content}</p>
                        <br/>
                        <Space>
                            {!fav ?<MdOutlineStarRate onClick={switchFavMessage}/> : <MdStarRate onClick={switchFavMessage}/>}
                        </Space>
                        <div>{time}</div>
                    </div> */}
        </Col>
      )}
    </Row>
  );
}

export default ChatBubble;
