import {
  Button,
  Col,
  Drawer,
  Empty,
  Image,
  Input,
  Layout,
  List,
  message,
  Row,
  Spin,
  Tabs,
  Typography,
  Upload,
} from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import './ChatArea.scss';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../hooks';
import ChatBubble from '../ChatBubble';
import chat from '../../../../assets/images/chat-empty.svg';
import { useSelector } from 'react-redux';
import { getChatArea, getChatAreaContentSelector } from './ChatAreaSlice';
import { ChatAreaProps } from '../../../Models/ChatAreaProps';
import { clearSendChatMessage, sendChatMessage, sendChatMessageSelector } from './SendMessage/SendMessageSlice';
import { clearReadChatMessage, readChatMessage } from './ReadMessage/ReadMessageSlice';
import { setSelectedBuddySelector } from '../SetSelectBuddySlice';
import {
  clearGetAttachmentMessage,
  getAllAttachments,
  getAttachmentMessageSelector,
  mediaProps,
} from '../Attachments/SendAttachmetns/GetAllAttachmentMessageSlice';
import { FiSend } from '@react-icons/all-files/fi/FiSend';
import { PaperClipOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { BiArrowBack } from '@react-icons/all-files/bi/BiArrowBack';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import { getHost } from '../../../../utils/hostDataFunction';
import { getUniversityResourceSelector } from '../../../../MainScreen/MainScreenSlice';
import { minutesFormatter } from '../../../../utils/fomatter';
import PageLoader from '../../../PageLoader';

const { Text } = Typography;
const { Header, Sider, Content, Footer } = Layout;
const { TabPane } = Tabs;

function ChatArea({ backtoBuddy, themeColor, selectedId, setSelectedId }: any) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { payload } = useSelector(getUniversityResourceSelector);

  const uniId = getHost();

  const messagesEndRef = useRef(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const [messageContent, setMessageContent] = useState<string>('');
  const [mediaSorted, setMediaSorted] = useState<any>({});
  const { status, isFetching, chatDetails } = useSelector(getChatAreaContentSelector);
  const {
    chatResponse,
    status: sendMessageStatus,
    isFetching: isSendingMessage,
  } = useSelector(sendChatMessageSelector);
  const {
    isFetching: isFetchingGetAttachment,
    status: getAttachmentStatus,
    chatAttachmentResponse,
  } = useSelector(getAttachmentMessageSelector);
  const { buddy } = useSelector(setSelectedBuddySelector);
  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    // console.log(status);
  }, [chatDetails, status]);

  useEffect(() => {
    if (sendMessageStatus === 'SUCCESS') {
      if (selectedId === undefined) {
        setSelectedId(buddy);
      } else {
        dispatch(getChatArea({ id: parseInt(buddy?.publicId), pubId: id }));
        dispatch(
          readChatMessage({
            buddyID: parseInt(buddy?.publicId),
            pubId: id,
          })
        );
      }
      setMessageContent('');
      dispatch(clearSendChatMessage());
    }
  }, [chatResponse]);

  function send() {
    // console.log(messageContent);
    if (messageContent || messageContent !== '') {
      dispatch(
        sendChatMessage({
          buddyID: parseInt(buddy?.publicId),
          message: messageContent,
          pubId: id,
        })
      );
    }
  }
  // console.log('=== buddy ===', buddy);

  const uploadProps = {
    name: 'file',
    action: `${process.env.REACT_APP_API_URL}/chat/buddy/${buddy?.publicId}/media`,
    headers: {
      authorization: 'authorization-text',
      session: JSON.parse(localStorage.getItem('sessionDetails') as string)?.sessionId,
      uniId: `${uniId}`,
    },

    onChange(info: any) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
        dispatch(getChatArea({ id: parseInt(buddy?.publicId), pubId: id }));
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  function callbackTab(e: string) {
    console.log(e);
  }

  const changeMediaObjectWithDate = (listItems: mediaProps[]) => {
    return listItems.map((item) => Object.assign({ documentTitle: dateToFromNowDaily(item.sendDate) }, item));
  };

  useEffect(() => {
    let sessionDetails = JSON.parse(localStorage.getItem('sessionDetails') as string);
    // if(!sessionDetails){
    //   navigate("/")
    // }
  }, []);

  useEffect(() => {
    if (visible) {
      dispatch(
        getAllAttachments({
          buddyID: parseInt(buddy?.publicId),
        })
      );
    } else {
      dispatch(clearGetAttachmentMessage());
    }
  }, [visible]);

  // const scrollToBottom = () => {
  //   // @ts-ignore
  //   messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  // };
  const scrollToBottom = () => {
    const chatArea = document.querySelector('.chatBody');
    if (chatArea) {
      chatArea.scrollTo({
        top: chatArea.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    let groupedMediaList = groupByCategory(
      changeMediaObjectWithDate(chatAttachmentResponse)
        .filter((item) => item.type === 'PICTURE')
        .reverse()
    );

    setMediaSorted(groupedMediaList);
  }, [chatAttachmentResponse]);

  useEffect(() => {
    scrollToBottom();
  }, [status]);

  // Returns duration
  function dateToFromNowDaily(myDate: string) {
    // get from-now for this date
    var fromNow = moment(myDate).fromNow();

    // ensure the date is displayed with today and yesterday
    return moment(myDate).calendar(null, {
      // when the date is closer, specify custom values
      lastWeek: '[Last] dddd',
      lastDay: '[Yesterday]',
      sameDay: '[Today]',
      nextDay: '[Tomorrow]',
      nextWeek: 'dddd',
      // when the date is further away, use from-now functionality
      sameElse: function () {
        return '[' + fromNow + ']';
      },
    });
  }
  const groupByCategory = (items: ({ documentTitle: string } & mediaProps)[]) =>
    items.reduce((group, product) => {
      const { documentTitle } = product;
      // @ts-ignore
      group[documentTitle] = group[documentTitle] ?? [];
      // @ts-ignore
      group[documentTitle].push(product);
      // console.log(group);
      return group;
    }, {});

  return (
    <>
      {/*Media Drawer Section*/}
      <Drawer
        title={t('Chat.ChatArea.drawerMediaTitle')}
        placement="right"
        onClose={onClose}
        visible={visible}
        className="drawerRight"
      >
        {isFetchingGetAttachment ? (
          <PageLoader />
        ) : (
          <Tabs defaultActiveKey="1" onChange={callbackTab}>
            <TabPane tab={t('Chat.ChatArea.tabPicturesText')} key="1">
              <Row>
                {Object.keys(mediaSorted).map((keyTitle: string) => {
                  return (
                    <>
                      <Row>
                        <div className="dateFilterLayout">
                          <div className="dateFilter"> {keyTitle} </div>
                          {mediaSorted[keyTitle].map((media: mediaProps) => {
                            return (
                              <Col span={24}>
                                <Image src={media.documentUrl} />
                              </Col>
                            );
                          })}
                        </div>
                      </Row>
                    </>
                  );
                })}
              </Row>
            </TabPane>
            <TabPane tab={t('Chat.ChatArea.tabDocumentsText')} key="2">
              {isFetchingGetAttachment ? (
                <PageLoader />
              ) : (
                <Row>
                  {chatAttachmentResponse
                    .filter((item) => item.type === 'DOCUMENT')
                    .map(({ documentUrl, documentName }) => {
                      return (
                        <Col span={8} style={{ margin: 5 }}>
                          <div>
                            <a href={documentUrl} download>
                              {documentName}
                            </a>
                          </div>
                        </Col>
                      );
                    })}
                </Row>
              )}
            </TabPane>
          </Tabs>
        )}
      </Drawer>
      {/* End of Media Drawer Section*/}

      {status === 'SUCCESS' && chatDetails?.length >= 0 ? (
        isFetching ? (
          <PageLoader />
        ) : (
          <div className="chatArea">
            <Content>
              <Row>
                <div className="buddieHeader">
                  {/* <Button className="hideBackMobile">GoBack</Button> */}
                  <div className="buddieHeaderArea">
                    {isMobile && (
                      <BiArrowBack
                        className="backToBuddyList"
                        onClick={() => {
                          backtoBuddy();
                          setSelectedId(undefined);
                          dispatch(clearReadChatMessage());
                        }}
                      />
                    )}
                    <img alt="example" src={buddy?.mediumProfileUrl} />

                    <div className="buddieTitleArea">
                      <div className="buddieTitle">{buddy?.firstName}</div>
                      <div className="buddieReplyTime">
                        {' '}
                        {`Typically replies in ${minutesFormatter(buddy?.avgResponseTimeInMinutes)}`}
                      </div>
                    </div>
                  </div>

                  <div className="mediaButtonLayout">
                    <Button className="btn btn-secondary-outline" onClick={showDrawer}>
                      {t('Chat.ChatArea.MediaButtonText')}
                    </Button>
                  </div>
                </div>
                <Col className="chatBody" span={24}>
                  {/* <img
                    className="chatBgImg"
                    alt="example"
                    src={backgroundImage}
                  /> */}
                  {/* <List
                    className="comment-list"
                    // header={`${data.length} replies`}
                    itemLayout="horizontal"
                    dataSource={chatDetails}
                    footer={<div ref={messagesEndRef}></div>}
                    renderItem={(item: ChatAreaProps) => (
                      <li className="chatBubbleArea">
                        <ChatBubble
                          id={item?.id}
                          favoriteMessage={item?.favoriteMessage}
                          content={item?.body}
                          time={item?.dateCreated}
                          buddyId={parseInt(buddy?.publicId)}
                          type={item?.type}
                          mediaType={item?.mediaType}
                          themeColor={themeColor}
                          minOffset={payload?.minOffset}
                        />
                      </li>
                    )}
                  /> */}

                  {chatDetails?.length > 0 ? (
                    <List
                      className="comment-list"
                      // header={`${data.length} replies`}
                      itemLayout="horizontal"
                      dataSource={chatDetails}
                      footer={<div ref={messagesEndRef}></div>}
                      renderItem={(item: ChatAreaProps) => (
                        <li className="chatBubbleArea">
                          <ChatBubble
                            id={item?.id}
                            favoriteMessage={item?.favoriteMessage}
                            content={item?.body}
                            time={item?.dateCreated}
                            buddyId={parseInt(buddy?.publicId)}
                            type={item?.type}
                            mediaType={item?.mediaType}
                            themeColor={themeColor}
                            minOffset={payload?.minOffset}
                          />
                        </li>
                      )}
                    />
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
              <Row className="chatInputArea">
                <div className="inputArea">
                  <Col
                    span={24}
                    // style={{
                    //   display: "flex",
                    //   flexDirection: "column",
                    //   position: "relative",
                    // }}
                  >
                    <div className="inputItems">
                      <Input
                        placeholder={t('Chat.ChatArea.inputMessageIn') || ''}
                        onChange={(e) => setMessageContent(e.target.value)}
                      />

                      <div className="btnArea">
                        <Upload {...uploadProps}>
                          <Button className="attachmentButton">
                            <PaperClipOutlined />
                          </Button>
                        </Upload>

                        <Button
                          loading={isSendingMessage}
                          className="send-button"
                          style={{ background: themeColor }}
                          onClick={() => send()}
                        >
                          <FiSend />
                          {t('Chat.ChatArea.btnsendMessageChat')}
                        </Button>
                      </div>

                      {/* <Button
                            loading={isSendingMessage}
                          className="sendMessageButtonChatMobile"
                          onClick={() => send()}
                        >
                          <FiSend className="sendIconChat" size={20} />
                        </Button> */}
                    </div>
                  </Col>
                </div>
              </Row>
            </Content>
          </div>
        )
      ) : (
        !isFetching && (
          <Empty
            description="Click on the Ambassador profile on your left to reply"
            // {t("Chat.ChatArea.chatEmptyDescription")}
            image={chat}
            className="chatEmpty"
          />
        )
      )}
    </>
  );
}

export default ChatArea;
function smoothScrollIntoView(
  node: HTMLElement | null,
  arg1: { behavior: string }
): (resolve: (value: unknown) => void, reject: (reason?: any) => void) => void {
  throw new Error('Function not implemented.');
}
