import React, { Suspense, useEffect, useState } from 'react';

import PersonCard from '../../../common/PersonCard/PersonCard';
import './PeopleTab.scss';
import { FiFilter } from '@react-icons/all-files/fi/FiFilter';
import FilterComponent from '../../../common/FilterComponent/FilterComponent';

import { Button, Col, Empty, Layout, Pagination, Row, Spin, Tabs } from 'antd';
import { getUniversityResourceSelector } from '../../../../MainScreen/MainScreenSlice';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../hooks';
import { fetchBuddyListByUniversityId, getBuddyListSelector, PersonProp } from './PeopleTabSlice';
import { useTranslation } from 'react-i18next';
import emptyImage from '../../../../assets/images/empty-image.png';
import MessageSuccessPopup from '../SayHelloPopup/MessageSuccessPopup';
import { getHost } from '../../../../utils/hostDataFunction';
import PageLoader from '../../../PageLoader';
import NoAmbassadors from '../NoAmbassadorsForm/NoAmbassadors';

const SayHelloPopup = React.lazy(() => import('../SayHelloPopup/SayHelloPopup'));

const { TabPane } = Tabs;
const { Content } = Layout;

export interface FilterOptions {
  page: number | undefined;
  size: number;
  country: string | undefined;
  location: number | undefined;
  programme: number | undefined;
  course: number | undefined;
  type: string | undefined;
  state: number | undefined;
}

function Sonnet({ data, setSayhelloVisible, setProfileId, enableWordPress }: any) {
  return (
    <>
      {data.map((item: PersonProp) => {
        return (
          <PersonCard
            {...item}
            setVisible={setSayhelloVisible}
            setProfileId={setProfileId}
            enableWordPress={enableWordPress}
          />
        );
      })}
    </>
  );
}

function PeopleTab() {
  const { t } = useTranslation();

  const [showfitler, setShowfilter] = useState<boolean>(true);
  const [sayhelloVisible, setSayhelloVisible] = useState<boolean>(false);
  const [isMessageSuccessModalVisible, setIsMessageSuccessModalVisible] = useState<boolean>(false);
  const [profileId, setProfileId] = useState<number>(0);
  const [userTermsVisiblity, SetUserTermsVisiblity] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<string>('0');
  const { isFetching, status, buddyListPayload } = useAppSelector(getBuddyListSelector);
  const [sayHelloPopUpData, setHelloPopUpData] = useState<any>({});

  const [enableWordPress, setEnableWordPress] = useState<any>(false);
  const [enableBothTabs, setEnableBothTabs] = useState<any>(false);

  const [filterOptions, setFilterOptions] = useState<FilterOptions>({
    country: undefined,
    location: undefined,
    course: undefined,
    programme: undefined,
    state: undefined,
    type: 'STUDENT',
    page: 0,
    size: 12,
  });

  const [structureProgrammes, setStructuredProgrammes] = useState([]);

  let dispatch = useDispatch();

  const { payload } = useAppSelector(getUniversityResourceSelector);
  const dynamicThemeColor = payload?.theme?.themeColor;
  const isNoBuddyFormEnable = payload?.univeristyConfig?.noBuddyFormEnabled;

  const countryCode = useAppSelector((state) => state.chatPopups.countryCode);

  useEffect(() => {
    if (dynamicThemeColor) {
      document.documentElement.style.setProperty('--theme-color', dynamicThemeColor);
    }
  }, [dynamicThemeColor]);

  const buddyTypes = payload?.univeristyConfig?.buddyTypes;

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    const wordPress = searchParams.get('wordpress');
    const studentLife = searchParams.get('studentLife');

    if (wordPress && studentLife) {
      setEnableBothTabs(true);
      setEnableWordPress(true);
    } else if (wordPress) {
      setEnableWordPress(true);
    }

    // let host = getHost();
    // if (host) {
    //   dispatch(fetchUniversityResourceById(host));
  }, []);

  useEffect(() => {
    if (payload) {
      SetUserTermsVisiblity(
        (payload?.univeristyConfig && payload?.univeristyConfig.countrySearchEnabled) ||
          (payload?.univeristyConfig && payload?.univeristyConfig.programmeEnabled) ||
          (payload?.univeristyConfig && payload?.univeristyConfig.courseEnabled)
      );

      if (payload?.univeristyConfig?.buddyTypes && payload?.univeristyConfig?.buddyTypes.length > 0) {
        setFilterOptions({
          ...filterOptions,
          type: payload?.univeristyConfig?.buddyTypes[parseInt('0')],
        });
        handleFilteration('type', payload?.univeristyConfig?.buddyTypes[parseInt('0')]);
      }

      const programmeArr = handleProgrammesForMultiSelect(payload?.programmes);
      setStructuredProgrammes(programmeArr);
    }
  }, [payload]);

  // useEffect(() => {
  //   let uniId = getHost();
  //   dispatch(
  //     fetchBuddyListByUniversityId({
  //       id: uniId ? uniId : "",
  //       filterOptions: filterOptions,
  //     })
  //   );
  // }, [filterOptions]);

  const handleProgrammesForMultiSelect = (programmes: any) => {
    let programmesArr: any = [];
    let courseArr: any = [];
    for (let index = 0; index < programmes.length; index++) {
      const element = programmes[index];

      for (let j = 0; j < element?.courses.length; j++) {
        const courseElement = element?.courses[j];
        let courseObj = {
          label: courseElement?.displayName ?? courseElement?.name,
          value: courseElement?.id,
        };
        courseArr[courseArr.length] = courseObj;
      }

      // let obj = {
      //   label: element?.displayName,
      //   value: element?.id,
      // };
      // programmesArr[programmesArr.length] = obj;
    }
    // console.log("=== courseArr ===", courseArr);

    return courseArr;
  };

  function callback(tabItem: string) {
    setFilterOptions({
      ...filterOptions,
      type: payload?.univeristyConfig?.buddyTypes[parseInt(tabItem)],
      page: 0,
    });
    handleFilteration('type', payload?.univeristyConfig?.buddyTypes[parseInt(tabItem)]);
    setCurrentTab(tabItem);
  }

  function onChangePagination(id: number) {
    // console.log('Pagination filter', filterOptions);
    setFilterOptions({ ...filterOptions, page: id - 1 });
    handleFilteration('page', id - 1);
  }

  const operations = (
    <>
      {userTermsVisiblity ? (
        <Button
          className={!showfitler ? 'inFilterButton' : 'inFilterButtonClicked'}
          type="default"
          icon={<FiFilter className="filterIcon" size={20} />}
          ghost
          style={{}}
          onClick={() => {
            setShowfilter(!showfitler);
          }}
        >
          {t('dashboard.PeopleTab.inFilterButton')}
        </Button>
      ) : (
        ''
      )}
    </>
  );

  const handleCallback = (data: any) => {
    setHelloPopUpData(data);
  };

  const handleFilteration = (key: any, value: any) => {
    let uniId = getHost();
    let filterObj: any = {};

    if (key === 'type') {
      filterObj.type = value;
    }

    if (key === 'country') {
      filterObj.country = value;
    }

    if (key === 'location') {
      filterObj.location = value;
    }

    if (key === 'programme') {
      filterObj.programme = value;
    }

    if (key === 'course') {
      filterObj.course = value;

      const selectedProgram = payload?.programmes.find((program) =>
        program.courses.some((course) => course.id === value)
      );

      if (selectedProgram) {
        filterObj.programme = selectedProgram.id;
      }
    }

    if (key === 'state') {
      filterObj.state = value;
    }

    if (key === 'page') {
      filterObj.page = value;
    }

    // setFilterOptions({ ...filterOptions, ...filterObj });

    dispatch(
      fetchBuddyListByUniversityId({
        id: uniId ? uniId : '',
        filterOptions: { ...filterOptions, ...filterObj },
      })
    );
  };

  const handleClearFilter = (filterObj: any) => {
    let uniId = getHost();
    setFilterOptions({ ...filterObj, page: 0 });
    setCurrentTab('0');
    dispatch(
      fetchBuddyListByUniversityId({
        id: uniId ? uniId : '',
        filterOptions: { ...filterObj },
      })
    );
  };

  const renderPersonCards = () => {
    return (
      <Sonnet
        data={buddyListPayload.items}
        setSayhelloVisible={setSayhelloVisible}
        setProfileId={setProfileId}
        enableWordPress={enableWordPress}
      />
    );
  };

  //console.log('=== enableWordPress ===', enableWordPress);
  // console.log('=== filter options ===', filterOptions);

  const buddyInfo = buddyListPayload.items.filter((item) => item.id === profileId)[0];

  const subTitle = (
    <>
      {t('dashboard.MessageSuccessPopup.titleHeaderSub1')} {''} {sayHelloPopUpData?.email} {''}{' '}
      {t('dashboard.MessageSuccessPopup.titleHeaderSub2')} {''} {buddyInfo?.profile.firstName} {''}
      {t('dashboard.MessageSuccessPopup.titleHeaderSub3')}. {''} {t('dashboard.MessageSuccessPopup.titleHeaderSub4')}
      <div className="referingToFriendText">{t('dashboard.MessageSuccessPopup.referingToFriendText')}</div>
    </>
  );
  const isMobileView = window.innerWidth < 768;
  const dynamicPadding = enableWordPress ? '0 0px' : isMobileView ? '0 20px' : '0 120px';

  return (
    <div className="peopleTabContent" style={{ padding: dynamicPadding }}>
      <MessageSuccessPopup
        isModalVisible={isMessageSuccessModalVisible}
        setIsModalVisible={setIsMessageSuccessModalVisible}
        prospectData={sayHelloPopUpData}
        buddies={buddyInfo}
        title="Your message has been sent!"
        subTitle={subTitle}
      />
      <Suspense fallback={<div>Loading...</div>}>
        {sayhelloVisible && (
          <SayHelloPopup
            buddies={buddyListPayload.items.filter((item) => item.id === profileId)[0]}
            visible={sayhelloVisible}
            setVisible={(isVisible, isSuccess) => {
              setSayhelloVisible(isVisible);
              setIsMessageSuccessModalVisible(isSuccess);
            }}
            id={profileId}
            handleCallback={handleCallback}
            structureProgrammes={structureProgrammes}
            countryCode={countryCode}
            enableWordPress={enableWordPress}
          />
        )}
      </Suspense>
      <Row>
        <Col xs={18}>
          <div className="bannerHeader bannerHeaderMobile ">
            {t('dashboard.PeopleTab.title')}
            {/*Start chatting with our...*/}
          </div>
        </Col>
        <Col xs={6} className="text-right">
          <Button
            className={!showfitler ? 'inFilterButton' : 'inFilterButtonClicked'}
            icon={<FiFilter className="filterIcon" />}
            ghost
            style={{
              backgroundColor: `${payload?.theme?.themeColor}`,
              borderColor: `${payload?.theme?.themeColor}`,
            }}
            onClick={() => {
              setShowfilter(!showfitler);
            }}
          >
            {t('dashboard.PeopleTab.inFilterButton')}
          </Button>
        </Col>
      </Row>
      <div className="showFilterComponent">
        {showfitler && (
          <FilterComponent
            filterOptions={filterOptions}
            count={buddyListPayload.items.length}
            setFilterOptions={setFilterOptions}
            currentTab={currentTab}
            handleFilteration={handleFilteration}
            handleClearFilter={handleClearFilter}
          />
        )}
      </div>
      {isFetching ? (
        <PageLoader />
      ) : (
        <>
          <div className="card-container">
            <Row style={{ width: '100%' }}>
              <Col span={24}>
                {buddyTypes && buddyTypes.length > 1 ? (
                  <Tabs
                    className="tabs"
                    style={{ color: `${payload?.theme?.themeColor}` }}
                    type="card"
                    size={'large'}
                    defaultActiveKey="0"
                    onChange={callback}
                    // tabBarExtraContent={operations}
                    activeKey={currentTab}
                  >
                    {payload?.univeristyConfig?.buddyTypes.map((tabItem: any, index) => {
                      return (
                        <TabPane
                          className="peopleTabStudentsTxt"
                          tab={tabItem.charAt(0).toUpperCase() + tabItem.slice(1).toLowerCase()}
                          key={index}
                        >
                          <div className="peopleTab">
                            <Row gutter={16}>
                              {buddyListPayload.items.length > 0
                                ? renderPersonCards()
                                : (isNoBuddyFormEnable && (
                                    <NoAmbassadors
                                      structureProgrammes={structureProgrammes}
                                      clearFilter={handleClearFilter}
                                    />
                                  )) || (
                                    <Empty
                                      image={emptyImage}
                                      // description={`${t('dashboard.PeopleTab.emptyTabText1')} ${tabItem} ${t(
                                      //   'dashboard.PeopleTab.emptyTabText2'
                                      // )}`}
                                      description="More ambassadors will be added soon. Please select another ambassador in the meantime."
                                    />
                                  )}
                            </Row>
                          </div>
                        </TabPane>
                      );
                    })}
                  </Tabs>
                ) : (
                  <div className="peopleTab">
                    <Row gutter={16}>{renderPersonCards()}</Row>
                  </div>
                )}
              </Col>
            </Row>
          </div>
          <Row>
            <Col span={24}>
              {buddyListPayload.items.length > 0 && (
                <Pagination
                  current={(filterOptions.page ?? 0) + 1}
                  total={buddyListPayload?.totalItems}
                  onChange={onChangePagination}
                  pageSize={12}
                  hideOnSinglePage={true}
                />
              )}
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}

export default PeopleTab;
