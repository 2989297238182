import React from 'react';
import { Row, Col, Card } from 'antd';
import SampleWallPaper from '../../../../assets/images/sample-wall-paper.png';
import ReactPlayer from 'react-player';
import { FaPlayCircle } from 'react-icons/fa';
import { checkMediaTypeByUrl } from '../common';
import emptyPostImg from '../../../../assets/images/empty-post-img.jpg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const imageStyle: React.CSSProperties = {
  width: '100%', // Set the width to 100% to fit the content
  height: '100%', // Set the height to 100% to fit the content
  objectFit: 'cover', // Cover the entire container while maintaining aspect ratio
};

const gridStyleNoImg: React.CSSProperties = {
  width: '100%',
  height: '300px',
  padding: 0,
  border: 'none',
  backgroundImage: `url(${emptyPostImg})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
};

const gridStyle1: React.CSSProperties = {
  width: '100%',
  height: '300px',
  padding: 0, // Remove padding
  border: 'none', // Remove border
};

const gridStyle2: React.CSSProperties = {
  width: '50%',
  height: '300px',
  padding: 0, // Remove padding
  border: 'none', // Remove border
};

const gridStyle3: React.CSSProperties = {
  width: '100%',
  height: '50%',
  padding: 0, // Remove padding
  border: 'none', // Remove border
};

const gridStyleLast1: React.CSSProperties = {
  width: '50%',
  height: '300px',
  padding: 0, // Remove padding
  border: 'none', // Remove border
};

const gridStyleLast2: React.CSSProperties = {
  width: '100%', // Set to 100% to take up full width
  height: '50%',
  padding: 0, // Remove padding
  border: 'none', // Remove border
};

const flexContainerStyleLast: React.CSSProperties = {
  display: 'flex',
  height: '100%',
};

const flexItemStyleLast: React.CSSProperties = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

const imageStyleLast: React.CSSProperties = {
  maxWidth: '100%',
  maxHeight: '100%',
  objectFit: 'cover',
};

const separatingVideoImage = (url: any) => {
  const type = checkMediaTypeByUrl(url);
  return type === 'image' ? (
    <LazyLoadImage
      effect="blur"
      src={url}
      style={imageStyle}
      wrapperProps={{
        style: { transitionDelay: '1s' },
      }}
    />
  ) : (
    <ReactPlayer
      url={url}
      width="100%"
      playIcon={<FaPlayCircle size={100} color="#d3d7cf" />}
      light
      style={imageStyle}
    />
  );
};

const GalleryThumbnail = ({ items }: any) => {
  const renderItems = () => {
    if (items?.length === 0) {
      return <Card.Grid style={gridStyleNoImg} hoverable={false} />;
    } else if (items?.length === 1) {
      return (
        <Card.Grid style={gridStyle1} hoverable={false}>
          {separatingVideoImage(items[0]?.url)}
        </Card.Grid>
      );
    } else if (items?.length === 2) {
      return (
        <>
          <Card.Grid style={gridStyle2} hoverable={false}>
            {separatingVideoImage(items[0]?.url)}
          </Card.Grid>
          <Card.Grid style={gridStyle2} hoverable={false}>
            {separatingVideoImage(items[1]?.url)}
          </Card.Grid>
        </>
      );
    } else if (items?.length === 3) {
      return (
        <>
          <Card.Grid style={gridStyle2} hoverable={false}>
            {separatingVideoImage(items[0]?.url)}
          </Card.Grid>
          <Card.Grid style={gridStyle2} hoverable={false}>
            <Card.Grid style={gridStyle3} hoverable={false}>
              {separatingVideoImage(items[1]?.url)}
            </Card.Grid>
            <Card.Grid style={gridStyle3} hoverable={false}>
              {separatingVideoImage(items[2]?.url)}
            </Card.Grid>
          </Card.Grid>
        </>
      );
    } else if (items.length === 4) {
      return (
        <>
          <Card.Grid style={gridStyleLast1} hoverable={false}>
            {separatingVideoImage(items[0]?.url)}
          </Card.Grid>
          <Card.Grid style={gridStyleLast1} hoverable={false}>
            <Card.Grid style={gridStyleLast2} hoverable={false}>
              <div style={flexContainerStyleLast}>
                <div style={imageStyle}>{separatingVideoImage(items[1]?.url)}</div>
              </div>
            </Card.Grid>
            <Card.Grid style={gridStyleLast2} hoverable={false}>
              <div style={flexContainerStyleLast}>
                <div style={imageStyle}>{separatingVideoImage(items[2]?.url)}</div>
                <div style={imageStyle}>{separatingVideoImage(items[3]?.url)}</div>
              </div>
            </Card.Grid>
          </Card.Grid>
          {/* {items.length > 5 && (
            <Col span={12}>
              <div className="more-items">+ {items.length - 5} more</div>
            </Col>
          )} */}
        </>
      );
    } else if (items.length >= 5) {
      return (
        <>
          <Card.Grid style={gridStyleLast1} hoverable={false}>
            {separatingVideoImage(items[0]?.url)}
          </Card.Grid>
          <Card.Grid style={gridStyleLast1} hoverable={false}>
            <Card.Grid style={gridStyleLast2} hoverable={false}>
              <div style={flexContainerStyleLast}>
                <div style={imageStyle}>{separatingVideoImage(items[1]?.url)}</div>
                <div style={imageStyle}>{separatingVideoImage(items[2]?.url)}</div>
              </div>
            </Card.Grid>
            <Card.Grid style={gridStyleLast2} hoverable={false}>
              <div style={flexContainerStyleLast}>
                <div style={imageStyle}>{separatingVideoImage(items[3]?.url)}</div>
                {items.length > 4 && <div style={imageStyle}>{separatingVideoImage(items[4]?.url)}</div>}
              </div>
            </Card.Grid>
          </Card.Grid>
          {/* {items.length > 5 && (
            <Col span={12}>
              <div className="more-items">+ {items.length - 5} more</div>
            </Col>
          )} */}
        </>
      );
    }
  };

  return <Row className="slMpGalleryRow">{renderItems()}</Row>;
};

export default GalleryThumbnail;
