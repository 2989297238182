import { getHost } from '../../../../utils/hostDataFunction';

export const getChatAreaRequest = async (user: { id: number; pubId: any }) => {
  // let userDetails = JSON.parse(<string>localStorage.getItem("token"));
  // console.log("user",user)
  let sessionDetails = JSON.parse(localStorage.getItem('sessionDetails') as string);
  let header: any = {
    'Content-Type': 'application/json',
    uniId: getHost(),
    pubId: user?.pubId,
  };

  if (sessionDetails?.sessionId) {
    header.session = sessionDetails?.sessionId;
  }

  return await fetch(`${process.env.REACT_APP_API_URL}/chat/buddy/${user.id}/history?favorite=false&page=0&size=40`, {
    method: 'GET',
    headers: header,
  }).then((res) => res.json());
};
