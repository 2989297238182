import { Button, Card, Col, Divider, Row, message } from 'antd';
import { DetailCard } from './DetailCard';
import BackArrow from '../../../../assets/images/back-arrow.png';
import ShareButton from '../../../../assets/images/share-button.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaPlayCircle, FaShareAlt } from 'react-icons/fa';
import { Gallery } from './Gallery';
import { BiArrowBack } from '@react-icons/all-files/bi/BiArrowBack';
import './PostDetail.scss';
import SampleWallPaper from '../../../../assets/images/sample-wall-paper.png';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
  addLikeToBuddyFeedRequest,
  getBuddyFeedsRequest,
  getStudentLifeSelector,
  clearAddLikeToBuddy,
} from '../StudentLifeSlice';
import { checkMediaTypeByUrl, handleBuddyDetails, handleBuddyName, handleMainImageVideo } from '../common';
import { getUniversityResourceSelector } from '../../../../MainScreen/MainScreenSlice';
import { getBuddyListSelector } from '../../Home/PeopleTab/PeopleTabSlice';
import { AiOutlineLike } from 'react-icons/ai';
import ReactPlayer from 'react-player';
import { getBuddyViewSelector } from '../../ViewProfileComponent/ViewProfileSlice';
import SayHelloPopup from '../../Home/SayHelloPopup/SayHelloPopup';
import { getIpDetailsSelector } from '../../../../api/IpDataSlice';
import PageLoader from '../../../PageLoader';

export const PostDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { getBuddyFeedResponse, getBuddyFeedStatus, isFetchingBuddyFeed } = useAppSelector(getStudentLifeSelector);
  const { payload } = useAppSelector(getUniversityResourceSelector);
  const { buddyListPayload } = useAppSelector(getBuddyListSelector);
  const postData = location.state && location.state.data;
  const PostLikeCount = location?.state && location?.state?.likeCount;
  const profileDetails = location?.state?.profileDetails ?? {};
  const { payload: ipDetailsPayload } = useAppSelector(getIpDetailsSelector);

  // console.log("=== location ===", location);

  const [mainImage, setMainImage] = useState<any>({});
  const [buddyFeed, setBuddyFeed] = useState({});
  const [buddyDetail, setBuddyDetail] = useState({});
  const [likeCount, setLikeCount] = useState(PostLikeCount || 0);

  const [structureProgrammes, setStructuredProgrammes] = useState([]);
  const { addFeedLikeStatus, error, likedPostId } = useAppSelector(getStudentLifeSelector);

  // console.log("=== postData ===", postData, payload);

  useEffect(() => {
    if (postData?.buddy?.buddyId && payload?.programmes) {
      dispatch(getBuddyFeedsRequest({ buddyId: postData?.buddy?.buddyId }));
      const programmeArr = handleProgrammesForMultiSelect(payload.programmes);
      setStructuredProgrammes(programmeArr);
    }
  }, [postData?.buddy?.buddyId, payload?.programmes, dispatch]);

  useEffect(() => {
    if (getBuddyFeedStatus === 'SUCCESS') {
      // console.log("=== getBuddyFeedResponse ===", getBuddyFeedResponse);
    }
  }, [getBuddyFeedStatus]);

  useEffect(() => {
    if (postData) {
      const leastObj = handleMainImageVideo(postData?.media);
      setMainImage(leastObj);
      // console.log("=== buddyId ===", postData?.buddy?.buddyId);
      // console.log("=== buddyListPayload ===", buddyListPayload);

      const buddyDetails = handleBuddyDetails(postData?.buddy?.buddyId, buddyListPayload);
      // console.log("=== buddyDetails ===", buddyDetails);

      setBuddyDetail(buddyDetails);
    }
  }, [postData]);

  useEffect(() => {
    if (addFeedLikeStatus === 'SUCCESS' && postData?.id === likedPostId) {
      setLikeCount(likeCount + 1);
      navigate(location.pathname, { state: { ...location.state, likeCount: likeCount + 1 }, replace: true });
      dispatch(clearAddLikeToBuddy());
    } else if (addFeedLikeStatus === 'FAILED') {
      message.error(error);
      dispatch(clearAddLikeToBuddy());
    }
  }, [addFeedLikeStatus]);

  const handleProgrammesForMultiSelect = (programmes: any) => {
    let programmesArr: any = [];
    let courseArr: any = [];
    for (let index = 0; index < programmes.length; index++) {
      const element = programmes[index];

      for (let j = 0; j < element?.courses.length; j++) {
        const courseElement = element?.courses[j];
        let courseObj = {
          label: courseElement?.displayName ?? courseElement?.name,
          value: courseElement?.id,
        };
        courseArr[courseArr.length] = courseObj;
      }

      // let obj = {
      //   label: element?.displayName,
      //   value: element?.id,
      // };
      // programmesArr[programmesArr.length] = obj;
    }
    // console.log("=== courseArr ===", courseArr);

    return courseArr;
  };

  const handleBackButton = () => {
    // if (profileDetails && profileDetails?.viaViewProfile) {
    //   navigate(`/buddy/${profileDetails?.id}`);
    // } else {
    //   navigate("/studentLife");
    // }
    navigate(-1);
  };

  const handlePreviewMedia = (item: {}) => {
    setMainImage(item);
  };

  const handleLike = (id: any) => {
    dispatch(
      addLikeToBuddyFeedRequest({
        ip: ipDetailsPayload?.ip,
        postId: id,
      })
    );
  };

  const separatingVideoImage = (url: any) => {
    const type = checkMediaTypeByUrl(url);
    return type === 'image' ? (
      <img
        src={mainImage.url}
        className="slPostDetailGalleryMainImage"
        alt="Slider Pic"
        style={{ objectFit: 'contain' }}
      />
    ) : (
      <ReactPlayer
        url={url}
        width="100%"
        playIcon={<FaPlayCircle size={100} color="#d3d7cf" />}
        light
        className="slPostDetailGalleryMainImage"
      />
    );
  };

  return (
    <>
      {isFetchingBuddyFeed ? (
        <PageLoader />
      ) : (
        <Row className="slPostDetailMainRow" style={{ overflowX: 'hidden' }}>
          <Col span={24} className="slPostDetailMainColumn">
            <Row className="slPostDetailNavRow">
              <Col span={1} className="slPostDetailBackButtonColumn">
                <Button
                  shape="circle"
                  icon={<BiArrowBack />}
                  size="large"
                  onClick={handleBackButton}
                  className="slPostDetailBackButton"
                ></Button>
              </Col>
              <Col span={1} offset={22} className="slPostDetailShareButtonColumn">
                {/* <Button icon={<FaShareAlt />} size="large" onClick={handleBackButton} className="slPostDetailShareButton">
              Share
            </Button> */}
              </Col>
            </Row>
            <Row className="slPostDetailRow" gutter={16}>
              <Col md={14} lg={14} xs={24} className="slPostDetailGalleryColumn">
                <Card
                  style={{ width: '100%', background: '#f1f1f1' }}
                  // cover={<img alt="example" src={mainImage?.url} className="slPostDetailGalleryMainImage" />}
                  cover={separatingVideoImage(mainImage?.url)}
                >
                  <div className="slPostDetailGalleryDiv">
                    <Gallery media={postData?.media} handlePreviewMedia={handlePreviewMedia} />
                  </div>
                </Card>
                {/* <div className="slPostDetailGalleryMainDiv">
              <img src={mainImage?.url} className="slPostDetailGalleryMainImage" />
              <div className="slPostDetailGalleryDiv">
                <Gallery media={postData?.media} handlePreviewMedia={handlePreviewMedia} />
              </div>
            </div> */}

                <p className="slMpDescription" style={{ wordWrap: 'break-word', overflowWrap: 'break-word' }}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `${postData?.textBody}`,
                    }}
                  />
                </p>

                <div className="slPostDetailGalleryDivider">
                  <Divider />
                </div>

                <Row>
                  <Col md={4} lg={4} xs={4}>
                    <Button
                      type="ghost"
                      icon={<AiOutlineLike />}
                      className="slPostDetailGalleryLikeButton"
                      onClick={() => handleLike(postData?.id)}
                    >
                      Like
                    </Button>
                  </Col>
                  {/* <Col md={2} lg={2} xs={2}>
                    <Button type="ghost" icon={<AiOutlineShareAlt />} className="slMpShareButton">
                  Share
                </Button>
                  </Col> */}
                  <Col
                    md={{ span: 3, offset: 17 }}
                    lg={{ span: 2, offset: 18 }}
                    xs={{ span: 4, offset: 16 }}
                    className="slPostDetailGalleryLikeText"
                  >
                    {likeCount} Likes
                  </Col>
                  {/* <Col span={2} className="slPostDetailGalleryShareText">
                14 Shares
              </Col> */}
                </Row>
              </Col>
              <Col md={10} lg={10} xs={24} className="slPostDetailDetailCardColumn">
                <DetailCard buddyDetail={postData?.buddy} payload={payload} structureProgrammes={structureProgrammes} />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};
