import { Avatar, Button, Card, Col, Divider, Row, Typography, Layout, message } from 'antd';
import SampleWallPaper from '../../../../assets/images/sample-wall-paper.png';
import { AiOutlineLike, AiOutlineShareAlt } from 'react-icons/ai';
import GalleryThumbnail from './GalleryThumbnail';
import { useNavigate } from 'react-router-dom';
import './MainPostCard.scss';
import { addLikeToBuddyFeedRequest, getStudentLifeSelector, clearAddLikeToBuddy } from '../StudentLifeSlice';
import { useAppSelector, useAppDispatch } from '../../../../hooks';
import { calculateTimeAgo, handleBuddyAvatar, handleBuddyDetails, handleBuddyName } from '../common';
import { useEffect, useState } from 'react';
import { getIpDetailsSelector } from '../../../../api/IpDataSlice';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import PlaceHolder from './PlaceHolder';

const { Meta } = Card;

const galleryItems = [SampleWallPaper, SampleWallPaper, SampleWallPaper, SampleWallPaper];

export const MainPostCard = ({ item, buddyListPayload }: any) => {
  const { payload } = useAppSelector(getIpDetailsSelector);

  // console.log("=== item ===", item);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { addFeedLikeStatus, error, likedPostId } = useAppSelector(getStudentLifeSelector);
  // const [errorMessageShown, setErrorMessageShown] = useState(false);

  const [buddyDetail, setBuddyDetail] = useState<any>({});
  const [likeCount, setLikeCount] = useState(item?.likeCount || 0);

  useEffect(() => {
    if (addFeedLikeStatus === 'SUCCESS' && item?.id === likedPostId) {
      setLikeCount(likeCount + 1);
    }
  }, [addFeedLikeStatus]);

  useEffect(() => {
    setLikeCount(item?.likeCount);
  }, [item]);

  useEffect(() => {
    if (buddyListPayload) {
      const buddyDetails = handleBuddyDetails(item?.buddyId, buddyListPayload);
      setBuddyDetail(buddyDetails);
    }
  }, [buddyListPayload]);

  const handleCardOnClick = (postData: any) => {
    navigate('/postDetail', { state: { data: postData, likeCount: likeCount } });
  };

  const handleLike = (id: any, event: React.MouseEvent) => {
    // Prevent the click event from propagating to the card
    event.stopPropagation();
    dispatch(
      addLikeToBuddyFeedRequest({
        ip: payload?.ip,
        postId: id,
      })
    );
  };

  const renderWrappedTopic = (description: any) => {
    // Remove HTML tags
    let plainText = description.replace(/<[^>]*>/g, '');
    let words = plainText.split(' ').slice(0, 40).join(' ');
    return words;
  };

  return (
    <LazyLoadComponent placeholder={<PlaceHolder />}>
      <Card onClick={() => handleCardOnClick(item)} className="slMainPostCard" style={{ zIndex: 0 }}>
        <Row gutter={16} className="slMainPostMetaRow">
          <Col span={24}>
            <div className="slMainPostCardMeta">
              <Avatar src={item?.buddy?.smallProfileUrl} size={64} />
              <div className="slMainPostCardMetaDetail">
                <div className="slMainPostCardMetaName">{item?.buddy?.name}</div>
                <div className="slMainPostCardMetaProgramme">{calculateTimeAgo(item?.created)}</div>
              </div>
            </div>
          </Col>
        </Row>
        {/* <Row> */}
        <GalleryThumbnail items={item?.media} />
        {/* </Row> */}
        <Layout className="slMainPostCardMetaFooter">
          <Typography.Paragraph className="slMpDescription" ellipsis={{ rows: 2, expandable: false, symbol: '...' }}>
            {item?.textBody ? <>{renderWrappedTopic(item?.textBody)} ...</> : <></>}
          </Typography.Paragraph>
          <Divider />
          <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Col md={4} lg={4} xs={4} style={{ display: 'flex', alignItems: 'flex-start' }}>
              <Button
                type="ghost"
                icon={<AiOutlineLike />}
                className="slMpLikeButton"
                onClick={(event) => handleLike(item?.id, event)}
                style={{ zIndex: 5 }}
              >
                Like
              </Button>
            </Col>
            {/* <Col span={2}>
          <Button type="ghost" icon={<AiOutlineShareAlt />} className="slMpShareButton">
            Share
          </Button>
        </Col> */}
            <Col
              md={{ span: 4, offset: 16 }}
              lg={{ span: 4, offset: 16 }}
              xs={{ span: 6, offset: 14 }}
              style={{ display: 'flex', justifyContent: 'flex-end', textAlign: 'right' }}
              className="slMpLikeText"
            >
              {likeCount} Likes
            </Col>
            {/* <Col span={2} className="slMpShareText">
          14 Shares
        </Col> */}
          </Row>
        </Layout>
      </Card>
    </LazyLoadComponent>
  );
};
