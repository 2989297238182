import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store";
import { I18nextProvider } from "react-i18next";
// import i18n from "./utils/i18n";
import "antd/dist/antd.min.css";
import "antd/dist/antd.css";
import { toast } from "react-toastify";
import loading = toast.loading;
import { Spin } from "antd";
import initializeI18n from "./utils/i18n";

const appDomain = `${window.location.protocol}//${window?.location?.host}`;

// Initialize i18n with the dynamic apiDomain
const i18n = initializeI18n(appDomain);

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <React.Suspense>
        <BrowserRouter>
          <Provider store={store}>
            <App />
          </Provider>
        </BrowserRouter>
      </React.Suspense>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
