import { phoneLengthsByCountry } from './phoneLengthsByCountry';

export const composeValidators =
  (...validators: any) =>
  (value: any) =>
    validators.reduce((error: any, validator: (arg0: any) => any) => error || validator(value), undefined);

export const required = (value: any) => (value ? undefined : 'error.validation.required');
export const emptyArray = (array: string) => (array !== '' ? undefined : 'is empty');
// @ts-ignore
export const emptyList = (list: any[]) => (list !== [] ? undefined : 'is empty');
export const isPositiveNumber = (number: number) => (number > 0 ? undefined : 'should be a positive number');
export const isOther = (number: string) => (number !== 'other' ? undefined : 'is empty');

export const minTextLimit = (inputText: string, limit: number) => {
  if (inputText.trim().length < limit) {
    return `error.validation.minlimit.${limit}`;
  } else {
    //return `Text limit is ${limit}`
    return undefined;
  }
};

export const textLimit = (inputText: string, limit: number) => {
  if (limit >= inputText.trim().length) {
    return undefined;
  } else {
    //return `Text limit is ${limit}`
    return `error.validation.limit.${limit}`;
  }
};
export function validateEmail(email: string) {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!re.test(email)) {
    return 'error.validation.invalidEmail';
  }
}

export function validatePhone(phoneNumber: string, dialCode: string, countryCode: string) {
  var phoneno = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,12}$/im;
  if (!phoneno.test(phoneNumber)) {
    return 'error.validation.invalidNumber';
  }

  const digitsOnly = phoneNumber.replace(/\D/g, '');
  const localNumberLength = digitsOnly.slice(dialCode.length).length;

  const expectedLength = phoneLengthsByCountry[countryCode.toUpperCase()];

  if (localNumberLength > expectedLength) {
    return `Phone number must be exactly ${expectedLength} digits long`;
  }
}

export function minLengthList(tagsList: string | any[]) {
  if (tagsList.length > 10) {
    return 'more than 10 tags';
  } else {
    return undefined;
  }
}

export function validatePassword(email: string) {
  const re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  if (!re.test(email)) {
    return 'Should contain at least 8 characters with at least a symbol, upper and lower case letters and a number';
  }
}

export const validateDifferentNumbers = (allValues: any) => (value: string) => {
  if (value === allValues.phone) {
    return "You've entered the same number for both fields";
  }
  return undefined;
};

// Handle HTTP errors since fetch won't.
export function handleErrors(response: { ok: any; json: () => { (): any; new (): any; message: string | undefined } }) {
  if (!response.ok) {
    // alert(JSON.stringify(response))
    throw Error(response.json().message);
  }
  return response;
}
