import { getHost } from '../../../../../utils/hostDataFunction';

export const reSendOTPRequest = async (pid: string) => {
  let sessionDetails = JSON.parse(localStorage.getItem('sessionDetails') as string);
  let uniId = getHost();
  return await fetch(`${process.env.REACT_APP_API_URL}/chat/prospect/${pid}/otp/resend`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: '*/*',
      uniId: uniId ? uniId : '',
      session: sessionDetails?.sessionId,
    },
  }).then((res) => res.json());
};
