import { getHost } from "../../../../../utils/hostDataFunction";

export type MessageProps = {
  message: string;
  buddyID: number;
  pubId?: any;
};

export type MessageValidationProps = {
  message: string;
  ip?: any;
};

export const sendMessageRequest = async (message: MessageProps) => {
  // let userDetails = JSON.parse(<string>localStorage.getItem("token"));
  console.log("message", message);

  try {
    let sessionDetails = JSON.parse(localStorage.getItem("sessionDetails") as string);
    let header: any = {
      "Content-Type": "application/json",
      uniId: getHost(),
    };

    if (message.pubId) {
      header.pubId = message.pubId;
    }

    if (sessionDetails?.sessionId) {
      header.session = sessionDetails?.sessionId;
    }
    const response = await fetch(`${process.env.REACT_APP_API_URL}/chat/buddy/${message.buddyID}/message`, {
      method: "POST",
      headers: header,
      body: JSON.stringify({
        message: message.message,
      }),
    });

    if (!response.ok) {
      // Handle non-2xx responses as errors
      const errorMessage = await response.text(); // Extract the error message from the response
      throw new Error(errorMessage);
    }

    const jsonResponse = await response.json();
    return jsonResponse;
  } catch (error) {
    throw error;
  }
};

export const validateMessageAPIRequest = async (message: MessageValidationProps) => {
  try {
    let header: any = {
      "Content-Type": "application/json",
      uniId: getHost(),
    };

    const response = await fetch(`${process.env.REACT_APP_API_URL}/chat/check-message`, {
      method: "POST",
      headers: header,
      body: JSON.stringify(message),
    });
    if (!response.ok) {
      // Handle non-2xx responses as errors
      const errorMessage = await response.text(); // Extract the error message from the response
      throw new Error(errorMessage);
    }

    const jsonResponse = await response.json();
    return jsonResponse;
  } catch (error) {
    throw error;
  }
};
