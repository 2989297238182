import { getHost } from '../../../../utils/hostDataFunction';

export interface SendEmailProps {
  senderEmail: string;
  receiverEmail: string;
  message: string;
}

export const sendEmailURLRequest = async (sendEmail: SendEmailProps) => {
  let uniId = getHost();

  return await fetch(`${process.env.REACT_APP_API_URL}/support/email-share`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      uniId: uniId ? uniId : '',
    },
    body: JSON.stringify(sendEmail),
  }).then((res) => res.json());
};
