import React, { useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import { Col, Row, Select, Typography } from 'antd';
import { useAppSelector } from '../../../hooks';
import { CoursesProp, getUniversityResourceSelector, Programmes, StateProp } from '../../../MainScreen/MainScreenSlice';
import { useTranslation } from 'react-i18next';
import './CustomAntDesign.scss';
const { Option } = Select;

function FilterComponent({
  filterOptions,
  setFilterOptions,
  count,
  currentTab,
  handleFilteration,
  handleClearFilter,
}: any) {
  const [courseList, setCourseList] = useState<CoursesProp[]>();

  const { payload } = useAppSelector(getUniversityResourceSelector);
  const { t } = useTranslation();
  const [selectKey, setSelectKey] = useState('initial-key');

  const clearFilters = () => {
    setSelectKey(`key-${Date.now()}`);

    setFilterOptions({
      country: undefined,
      location: undefined,
      course: undefined,
      programme: undefined,
      state: undefined,
      type: payload?.univeristyConfig?.buddyTypes[parseInt('0')],
      page: 0,
      size: 12,
    });

    handleClearFilter({
      country: undefined,
      location: undefined,
      course: undefined,
      programme: undefined,
      state: undefined,
      type: payload?.univeristyConfig?.buddyTypes[parseInt('0')],
      page: 0,
      size: 12,
    });
  };

  useEffect(() => {
    if (!filterOptions.programme && payload?.programmes) {
      const allCourses = payload.programmes.flatMap((programme: Programmes) => programme.courses);
      setCourseList(allCourses);
    } else if (filterOptions.programme) {
      const selectedProgrammeCourses =
        payload?.programmes.find((programme: Programmes) => programme.id === filterOptions.programme)?.courses || [];
      setCourseList(selectedProgrammeCourses);
    } else if (filterOptions.state) {
      const programmesInState = payload?.states.find((state) => state.id === filterOptions.state)?.programmes || [];
      const allCoursesInState = programmesInState.flatMap((programme: Programmes) => programme.courses);
      setCourseList(allCoursesInState);
    }
  }, [filterOptions.programme, filterOptions.state, payload]);

  function onChangeCountry(e: string) {
    setFilterOptions({ ...filterOptions, country: e });
    handleFilteration('country', e);
  }

  function onChangeLocation(e: string) {
    setFilterOptions({ ...filterOptions, location: e });
    handleFilteration('location', e);
  }

  function onChangeProgramme(e: number) {
    setFilterOptions({ ...filterOptions, programme: e, course: undefined });
    // setFilterOptions((prevState: any) => ({ ...prevState, programme: e, course: undefined }));
    setSelectKey(`programme-${e}-${Date.now()}`);
    // handleFilteration('programme', e);
  }

  function onChangeState(e: number | undefined) {
    setFilterOptions({ ...filterOptions, state: e });
    handleFilteration('state', e);
  }

  useEffect(() => {
    handleFilteration('programme', filterOptions.programme);
  }, [filterOptions.programme]);

  function onChangeCourse(courseId: number | undefined) {
    if (courseId === undefined) {
      setFilterOptions({
        ...filterOptions,
        course: undefined,
      });
    } else {
      const programmeId = payload?.programmes.find((p) => p.courses.some((c) => c.id === courseId))?.id;
      setFilterOptions({
        ...filterOptions,
        course: courseId,
        programme: programmeId,
      });
    }

    handleFilteration('course', courseId);
  }

  const enabledFilters = [
    payload?.univeristyConfig?.countrySearchEnabled,
    payload?.univeristyConfig?.activeMultiLocations,
    payload?.univeristyConfig?.programmeEnabled,
    payload?.univeristyConfig?.courseEnabled,
    payload?.univeristyConfig?.stateEnabled,
  ].filter(Boolean).length;

  const colSize = enabledFilters > 0 ? 24 / enabledFilters : 24;

  return (
    <div className={'filterLayout'}>
      <Row className="filterIn" gutter={10}>
        {payload?.univeristyConfig && payload?.univeristyConfig.countrySearchEnabled && (
          <Col lg={colSize} xs={24} style={{ marginBottom: '3px' }}>
            {/* <Title level={5}>
                {t("dashboard.FilterComponent.filterTitleCountry")}
              </Title> */}
            <Select
              className="always-show-clear"
              showSearch
              showArrow
              mode={'tags'}
              style={{ width: '100%' }}
              onChange={onChangeCountry}
              value={filterOptions.country}
              onClear={() => onChangeCountry('')}
              allowClear={true}
              placeholder={t('dashboard.FilterComponent.filterTitleCountry')}
              optionFilterProp="children"
              maxTagCount="responsive"
              maxTagTextLength={10}
              filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              filterSort={(optionA, optionB) =>
                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
              }
            >
              {payload?.buddyCountries.map((country) => {
                return <Option value={country.code}>{country.name}</Option>;
              })}
            </Select>
          </Col>
        )}
        {payload?.univeristyConfig && payload?.univeristyConfig?.activeMultiLocations && (
          <Col lg={colSize} xs={24} style={{ marginBottom: '3px' }}>
            {/* <Title level={5}>
                {t("dashboard.FilterComponent.filterLocationCountry")}
              </Title> */}
            <Select
              className="always-show-clear"
              showSearch
              showArrow
              style={{ width: '100%' }}
              onChange={onChangeLocation}
              value={filterOptions.location}
              onClear={() => onChangeLocation('')}
              allowClear={true}
              placeholder={t('dashboard.FilterComponent.filterLocationCountry')}
              optionFilterProp="children"
              filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              filterSort={(optionA, optionB) =>
                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
              }
            >
              {payload?.locations.map((location) => {
                return <Option value={location.id}>{location.locationName}</Option>;
              })}
            </Select>
          </Col>
        )}
        {payload?.univeristyConfig && payload?.univeristyConfig.programmeEnabled && (
          <Col lg={colSize} xs={24} style={{ marginBottom: '3px' }}>
            {/* <Title level={5}>
                {t("dashboard.FilterComponent.filterTitleProgramme")}
              </Title> */}

            <Select
              className="always-show-clear"
              showSearch
              placeholder={t('dashboard.FilterComponent.filterTitleProgramme')}
              optionFilterProp="children"
              onClear={() => onChangeProgramme(-1)}
              style={{ width: '100%' }}
              value={filterOptions.programme}
              allowClear={true}
              onChange={onChangeProgramme}
              filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              filterSort={(optionA, optionB) =>
                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
              }
            >
              {payload?.programmes.map((programme) => {
                return <Option value={programme.id}>{programme.name}</Option>;
              })}
            </Select>
          </Col>
        )}
        {payload?.univeristyConfig && payload?.univeristyConfig.courseEnabled && (
          <Col lg={colSize} xs={24}>
            {/* <Title level={5}>
                {t("dashboard.FilterComponent.filterTitleCourse")}
              </Title> */}
            <Select
              className="filter1 always-show-clear"
              key={selectKey}
              showSearch
              allowClear={true}
              value={filterOptions.course}
              // onClear={clearFilters}
              style={{ width: '100%' }}
              placeholder={t('dashboard.FilterComponent.filterTitleCourse')}
              optionFilterProp="children"
              onChange={onChangeCourse}
              filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              filterSort={(optionA, optionB) =>
                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
              }
            >
              {courseList?.map((course) => {
                return <Option value={course.id}>{course.name}</Option>;
              })}
            </Select>
          </Col>
        )}
        {payload?.univeristyConfig && payload?.univeristyConfig.stateEnabled && (
          <Col lg={colSize} xs={24} style={{ marginBottom: '3px' }}>
            <Select
              className="always-show-clear"
              showSearch
              showArrow
              style={{ width: '100%' }}
              onChange={onChangeState}
              value={filterOptions.state}
              onClear={() => onChangeState(undefined)}
              allowClear={true}
              placeholder="Search by State"
              optionFilterProp="children"
              filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              filterSort={(optionA, optionB) =>
                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
              }
            >
              {payload?.states.map((state: StateProp) => (
                <Option key={state.id} value={state.id}>
                  {state.name}
                </Option>
              ))}
            </Select>
          </Col>
        )}
        {!!filterOptions?.location ||
        !!filterOptions?.country ||
        !!filterOptions?.programme ||
        !!filterOptions?.state ? (
          <Col className="search_footer">
            <Typography className="show_result_count">
              {t('dashboard.FilterComponent.showResultCountText1')} {count}{' '}
              {t('dashboard.FilterComponent.showResultCountText2')}
            </Typography>
            <div onClick={clearFilters} className="clear_search">
              {' '}
              <Typography className="clear_search">
                <MdClose />
                {t('dashboard.FilterComponent.filterClearSearch')}
              </Typography>
            </div>
          </Col>
        ) : (
          <></>
        )}
      </Row>
    </div>
  );
}

export default FilterComponent;
