import { Button, Layout, Result } from 'antd';
import React, { useEffect, useState } from 'react';
import BuddyList from './buddyList/BuddyList';
import ChatArea from './ChatArea/ChatArea';
import HeaderMain from '../../common/HeaderMain/HeaderMain';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import './Chat.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getAllBuddyListSelector } from './buddyList/BuddyListSlice';
import { ChatBuddyProps } from '../../Models/ChatBuddyProps';
import { isBrowser } from 'react-device-detect';
import { useAppSelector } from '../../../hooks';
import { fetchUniversityResourceById, getUniversityResourceSelector } from '../../../MainScreen/MainScreenSlice';
import { getHost } from '../../../utils/hostDataFunction';

function Chat() {
  const navigate = useNavigate();
  const location = useLocation();
  let dispatch = useDispatch();
  let sessionDetails = JSON.parse(localStorage.getItem('sessionDetails') as string);
  let { status } = useSelector(getAllBuddyListSelector);
  const [collapsed, setCollapsed] = useState(true);
  const { payload } = useAppSelector(getUniversityResourceSelector);
  const { id, chatId } = useParams();
  const [selectedId, setSelectedId] = useState<ChatBuddyProps>();

  // console.log("=== location ===", location, id, chatId);

  useEffect(() => {
    // if (!sessionDetails) {
    //     navigate("/")
    // }
    let host = getHost();
    // console.log("=== host ===", host);

    if (host) {
      dispatch(fetchUniversityResourceById(host));
    }
  }, []);

  const showDrawer = () => {
    setCollapsed(!collapsed);
  };

  const onClose = () => {
    setCollapsed(true);
  };

  const handleChatOpen = () => {
    setCollapsed(!collapsed);
  };

  // console.log('=== collapsed ===', collapsed);

  return (
    <Layout>
      {status === 'FAILED' ? (
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
          extra={
            <Button type="primary" onClick={() => navigate(-1)}>
              Back Home
            </Button>
          }
        />
      ) : (
        <>
          <HeaderMain />
          <Layout className="">
            <div className="buddieListAreaLayout">
              {isBrowser ? (
                <>
                  <BuddyList
                    onClose={onClose}
                    themeColor={payload?.theme?.themeColor}
                    selectedChatId={chatId}
                    setSelectedId={setSelectedId}
                    selectedId={selectedId}
                  />
                  <div className="buddieChatAreaLayout">
                    <ChatArea
                      backtoBuddy={showDrawer}
                      themeColor={payload?.theme?.themeColor}
                      setSelectedId={setSelectedId}
                      selectedId={selectedId}
                    />
                  </div>
                </>
              ) : (
                <>
                  {collapsed ? (
                    <BuddyList
                      onClose={onClose}
                      themeColor={payload?.theme?.themeColor}
                      selectedChatId={chatId}
                      handleChatOpen={handleChatOpen}
                      setSelectedId={setSelectedId}
                      selectedId={selectedId}
                    />
                  ) : (
                    <div className="buddieChatAreaLayout">
                      <ChatArea
                        backtoBuddy={showDrawer}
                        themeColor={payload?.theme?.themeColor}
                        setSelectedId={setSelectedId}
                        selectedId={selectedId}
                      />
                    </div>
                  )}
                </>
              )}
              {/* {!collapsed && !isBrowser && (
                <BuddyList onClose={onClose} themeColor={payload?.theme?.themeColor} selectedChatId={chatId} />
              )}
              {collapsed && !isBrowser && (
                <div className="buddieChatAreaLayout">
                  <ChatArea backtoBuddy={showDrawer} themeColor={payload?.theme?.themeColor} />
                </div>
              )} */}
            </div>
          </Layout>
          {/* <FooterMain/> */}
        </>
      )}
    </Layout>
  );
}

export default Chat;
