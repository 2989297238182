import '../App.scss';
import './MobileMenu.scss';
import { Button, Layout, Menu, Radio, Space } from 'antd';
import { Link, useNavigate } from 'react-router-dom';

import { FaHome } from '@react-icons/all-files/fa/FaHome';
import { FaShareAlt } from '@react-icons/all-files/fa/FaShareAlt';
import { FaFacebookSquare } from '@react-icons/all-files/fa/FaFacebookSquare';
import { FaInstagram } from '@react-icons/all-files/fa/FaInstagram';
import { FaTwitter } from '@react-icons/all-files/fa/FaTwitter';
import { FaCopy } from '@react-icons/all-files/fa/FaCopy';
import { FaLanguage } from '@react-icons/all-files/fa/FaLanguage';
import SharePageEmailPopup from '../components/body/Home/SharePageEmailPopup/SharePageEmailPopup';
import { MdLogout } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { clearRegisterProspects } from '../components/body/RegisterProspect/RegisterProspectSlice';
import { useAppSelector } from '../hooks';
import { getUniversityResourceSelector } from './MainScreenSlice';

const { SubMenu } = Menu;

function MobileMenu() {
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const { payload } = useAppSelector(getUniversityResourceSelector);

  function logout() {
    dispatch(clearRegisterProspects());
    localStorage.removeItem('sessionDetails');
    navigate('/');
  }
  return (
    <Menu style={{ width: '100vw' }} mode="inline">
      <Menu.Item key="0">
        <Link to={'/'} className="dropDownShareButton">
          <FaHome size={18} style={{ marginRight: '6px' }} />
          <div>Home</div>
        </Link>
      </Menu.Item>
      <SubMenu key="sub1" title="Share" icon={<FaShareAlt size={18} />}>
        <Menu.Item key="1">
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <FaFacebookSquare size={18} style={{ marginRight: '10px' }} />
            Facebook
          </span>
        </Menu.Item>
        <Menu.Item key="2">
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <FaInstagram size={18} style={{ marginRight: '10px' }} />
            Instagram
          </span>
        </Menu.Item>
        <Menu.Item key="3">
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <FaTwitter size={18} style={{ marginRight: '10px' }} />
            Twitter
          </span>
        </Menu.Item>
        <Menu.Item key="4">
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <SharePageEmailPopup type={''} />
          </span>
        </Menu.Item>
        <Menu.Item key="5">
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <FaCopy size={18} style={{ marginRight: '10px' }} />
            Copy URL
          </span>
        </Menu.Item>
      </SubMenu>

      <Menu.Item key="0">
        {payload?.univeristyConfig?.enableFeed && (
          <Link to={'/studentLife'} className="dropDownShareButton">
            <FaHome size={18} style={{ marginRight: '6px' }} />
            <div>Student Life</div>
          </Link>
        )}
      </Menu.Item>

      <Menu.Divider />
    </Menu>
  );
}

export default MobileMenu;
