import React, { memo, useEffect, useState } from 'react';
import './YourSelfContainer.scss';
import { Checkbox, Col, Form as AntForm, FormInstance, Input, Popover } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
  setName,
  setEmail,
  setPhone,
  setChannels,
  setMessageContent,
  setWhatsAppNumber,
  setIsWhatsAppNumber,
  setDialCode,
  setCountryCode,
} from './SayHelloPopupSlice';
import { getSayHelloPopupSelector } from './SayHelloPopupSlice';
import ReCAPTCHA from 'react-google-recaptcha';
import { useCountryCode } from '../PeopleTab/useCountryCode';

import {
  composeValidators,
  minTextLimit,
  required,
  validateEmail,
  validatePhone,
  validateDifferentNumbers,
} from '../../../../utils/Validations/Validations';
import { UniversityResourceState } from '../../../../MainScreen/MainScreenSlice';
import { getIpDetailsSelector } from '../../../../api/IpDataSlice';
import { domainValidation } from '../../../common/utils';

export type channelTypeProps = 'EMAIL' | 'SMS' | 'WHATSAPP';

export type RegisterProspectProps = {
  message: string;
  email: string;
  phone: string;
  whatsappNumber: string | undefined;
  name: string;
  channels: channelTypeProps[];
  isWhatsAppNumber: boolean;
  courseId: string[];
  dialCode: string;
};

interface YourSelfContainerProps {
  onNext: (regProps: RegisterProspectProps) => void;
  messageContent: string;
  initValue: RegisterProspectProps;
  agreedToTerms: boolean;
  setAgreedToTerms: (checked: boolean) => void;
  universityResourceState: UniversityResourceState | null;
  countryCode?: any;
  setIsRecaptchaValidate?: any;
}

const YourSelfContainer = ({
  onNext,
  messageContent,
  initValue,
  agreedToTerms,
  setAgreedToTerms,
  universityResourceState,
  countryCode,
  setIsRecaptchaValidate,
}: YourSelfContainerProps) => {
  const { payload } = useAppSelector(getIpDetailsSelector);
  const formRef = React.createRef<FormInstance>();
  const [reqRequest, setReqRequest] = useState<RegisterProspectProps>(initValue);
  const [differentWhatsApNumber, setDifferentWhatsApNumber] = useState(false);

  const initialCountryCode = useCountryCode();
  const [isCountryCode, setIsCountryCode] = useState<string>('');
  // const [isRecaptchaValidate, setIsRecaptchaValidate] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const prospectUserInfo = useAppSelector((state) => state.chatPopups);

  useEffect(() => {
    if (initialCountryCode) {
      setIsCountryCode(initialCountryCode);
    }
  }, [initialCountryCode]);

  useEffect(() => {
    dispatch(setCountryCode(isCountryCode));
  }, [dispatch, isCountryCode]);

  useEffect(() => {
    setReqRequest({
      message: prospectUserInfo.messageContent,
      email: prospectUserInfo.email,
      phone: prospectUserInfo.phone,
      whatsappNumber: prospectUserInfo.whatsappNumber,
      name: prospectUserInfo.name,
      channels: prospectUserInfo.channels,
      isWhatsAppNumber: prospectUserInfo.isWhatsAppNumber,
      courseId: prospectUserInfo.courseId,
      dialCode: prospectUserInfo.dialCode ?? '',
    });
  }, [prospectUserInfo]);

  const isWhatsAppNumber = useAppSelector((state) => state.chatPopups.isWhatsAppNumber);

  const handleWhatsAppChange = (isChecked: boolean) => {
    dispatch(setIsWhatsAppNumber(!isChecked));
    if (!isChecked) {
      dispatch(setWhatsAppNumber(''));
    }
  };

  useEffect(() => {
    setDifferentWhatsApNumber(!isWhatsAppNumber);
  }, [isWhatsAppNumber]);

  // useEffect(() => {
  //   setReqRequest({ ...reqRequest, message: messageContent });
  // }, [messageContent]);

  useEffect(() => {}, [reqRequest]);

  const { t } = useTranslation();

  const onFinish = (values: any) => {};

  // const onChange = (checkedValues: CheckboxValueType[]) => {
  //   setReqRequest({
  //     ...reqRequest,
  //     channels: checkedValues as [channelTypeProps],
  //   });
  // };

  // const plainOptions = ['SMS', 'WHATSAPP'];
  // const CheckboxGroup = Checkbox.Group;

  const handleFieldChange = (fieldName: any, value: any) => {
    setReqRequest((prev) => ({ ...prev, [fieldName]: value }));
    switch (fieldName) {
      case 'name':
        dispatch(setName(value));
        break;
      case 'email':
        dispatch(setEmail(value));
        break;
      case 'phone':
        dispatch(setPhone(value));
        break;
      case 'channels':
        dispatch(setChannels(value));
        break;
      case 'whatsApp':
        dispatch(setWhatsAppNumber(value));
        break;
      case 'messageContent':
        dispatch(setMessageContent(value));
        break;
      case 'dialCode':
        dispatch(setDialCode(value));
        break;
    }
  };

  const content = (
    <div className="chatRulesPopoverLayout">
      <div className="dosTextHeader">{t('dashboard.SayHelloPopup.dosTextHeader')}</div>
      <div className="dosTextDescrip">{t('dashboard.SayHelloPopup.dosTextDescrip')} </div>
      <div className="dontsTextHeader">{t('dashboard.SayHelloPopup.dontsTextHeader')}</div>
      <div className="dontsTextDescrip">{t('dashboard.SayHelloPopup.dontsTextDescrip')} </div>
    </div>
  );
  // console.log('== universityResourceState ==', universityResourceState);

  // @ts-ignore

  const formState = useAppSelector(getSayHelloPopupSelector);

  const courseId = useAppSelector((state) => state.chatPopups.courseId);

  const handleOnSubmit = () => {
    const registerProspectData: RegisterProspectProps = {
      message: formState.messageContent,
      email: formState.email,
      phone: formState.phone,
      whatsappNumber: formState.whatsappNumber,
      name: formState.name,
      channels: formState.channels,
      isWhatsAppNumber: formState.isWhatsAppNumber,
      courseId,
      dialCode: formState.dialCode,
    };

    onNext(registerProspectData);
  };

  const onChangeReCaptcha = (value: any) => {
    // console.log('=== recaptchaValue ===', value);
    if (value) {
      setIsRecaptchaValidate(true);
    }
  };

  const onExpiredReCaptcha = () => {
    setIsRecaptchaValidate(false);
  };

  // const compareDomain = (domain: any) => {
  //   const restrictedDomains = [
  //     'shoolini.univiser.io',
  //     'amraksl.univiser.io',
  //     'ecusl.univiser.io',
  //     'uclsl.univiser.io',
  //     'ricsl.univiser.io',
  //     'mitwpu.univiser.io',
  //   ];
  //   return restrictedDomains.includes(domain);
  // };

  // const domainValidation = () => !compareDomain(universityResourceState?.domain);

  // console.log('==universityResourceState Return==', universityResourceState?.univeristyConfig);

  return (
    <>
      <div className="addSummeryForm">
        <Form
          initialValues={formState}
          onSubmit={handleOnSubmit}
          render={({ handleSubmit }: any) => (
            <form className="addSummeryFormIn" onSubmit={handleSubmit} id={'addSummeryForm'}>
              <Content>
                <AntForm ref={formRef} name="control-ref" onFinish={onFinish} layout="vertical">
                  <div className="yourName">
                    <Field name="name" validate={composeValidators(required, (text: string) => minTextLimit(text, 3))}>
                      {({ input, meta, ...rest }) => (
                        <AntForm.Item
                          className="yourNameText"
                          name="name"
                          label={t('dashboard.YourSelfContainer.yourName')}
                          validateStatus={meta.touched && meta.error ? 'error' : ''}
                          help={meta.touched && t(meta.error)}
                          rules={[{ required: true }]}
                        >
                          <div className="yourNameInput">
                            <Input
                              className="ant-input yourNameInputIn"
                              value={reqRequest?.name}
                              placeholder={t('dashboard.YourSelfContainer.yourNameHint') || ''}
                              onChange={(e) => handleFieldChange('name', e.target.value)}
                              {...rest}
                            />
                          </div>
                        </AntForm.Item>
                      )}
                    </Field>
                  </div>

                  <div className="yourEmailAddress">
                    <Field
                      className="yourEmailAddressIn"
                      placeholder={t('dashboard.YourSelfContainer.yourEmailAddressHint')}
                      name="email"
                      validate={composeValidators(required, (input: string) => validateEmail(input))}
                    >
                      {({ input, meta, ...rest }) => (
                        <AntForm.Item
                          className="yourEmailAddressText"
                          name="email"
                          tooltip={t('dashboard.SayHelloPopup.infoButton')}
                          label={t('dashboard.YourSelfContainer.yourEmailAddress')}
                          validateStatus={meta.touched && meta.error ? 'error' : ''}
                          help={meta.touched && t(meta.error)}
                          rules={[{ required: true }]}
                        >
                          <div className="yourEmailAddressInput">
                            <Input
                              onChange={(e) => handleFieldChange('email', e.target.value)}
                              {...rest}
                              value={reqRequest?.email}
                            />
                          </div>
                        </AntForm.Item>
                      )}
                    </Field>
                  </div>
                  <Field name="yourMobileNumber">
                    {({ input, meta, ...rest }) => (
                      <AntForm.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) => prevValues.gender !== currentValues.gender}
                      >
                        <div className="yourMobileNumber">
                          <div>
                            <Field
                              className="yourMobileNumberIn"
                              placeholder={t('dashboard.YourSelfContainer.yourMobileNumberHint')}
                              name="phone"
                              validate={composeValidators(required, (input: string) =>
                                validatePhone(input, prospectUserInfo.dialCode, isCountryCode)
                              )}
                            >
                              {({ input, meta, ...rest }) => (
                                <AntForm.Item
                                  className="yourMobileNumberText"
                                  name="phone"
                                  validateStatus={meta.touched && meta.error ? 'error' : ''}
                                  help={meta.touched && t(meta.error)}
                                  label={t('dashboard.YourSelfContainer.yourMobileNumber')}
                                  rules={[{ required: true }]}
                                >
                                  <div className="yourMobileNumberInput">
                                    <PhoneInput
                                      searchPlaceholder="Search"
                                      country={countryCode ? countryCode.toLowerCase() : ''}
                                      enableSearch={true}
                                      containerClass="yourEmailAddressIn"
                                      placeholder="Enter phone number"
                                      value={reqRequest?.phone}
                                      onChange={(value: string, country: any) => {
                                        handleFieldChange('phone', value);
                                        handleFieldChange('dialCode', country?.dialCode);
                                        setIsCountryCode(country.countryCode);
                                      }}
                                      {...rest}
                                    />
                                  </div>
                                </AntForm.Item>
                              )}
                            </Field>
                          </div>
                        </div>
                      </AntForm.Item>
                    )}
                  </Field>

                  <Checkbox
                    className="Checkbox"
                    checked={isWhatsAppNumber}
                    onChange={(e) => handleWhatsAppChange(!e.target.checked)}
                  >
                    {t('dashboard.YourSelfContainer.thisIsMyWhatsApp')}
                  </Checkbox>

                  {!isWhatsAppNumber && (
                    <Field name="yourWhatsAppNumber">
                      {({ input, meta, ...rest }) => (
                        <AntForm.Item
                          noStyle
                          shouldUpdate={(prevValues, currentValues) => prevValues.gender !== currentValues.gender}
                        >
                          <div className="yourMobileNumber">
                            <div>
                              <Field
                                className="yourMobileNumberIn"
                                placeholder={t('dashboard.YourSelfContainer.yourMobileNumberHint')}
                                name="whatsappNumber"
                                validate={composeValidators(
                                  required,
                                  (input: string) => validatePhone(input, prospectUserInfo.dialCode, isCountryCode),
                                  validateDifferentNumbers(reqRequest)
                                )}
                              >
                                {({ input, meta, ...rest }) => (
                                  <AntForm.Item
                                    className="yourMobileNumberText"
                                    name="whatsappNumber"
                                    validateStatus={meta.touched && meta.error ? 'error' : ''}
                                    help={meta.touched && t(meta.error)}
                                    label={t('dashboard.YourSelfContainer.whatsAppNumber')}
                                    rules={[{ required: true }]}
                                  >
                                    <div className="yourMobileNumberInput">
                                      <PhoneInput
                                        searchPlaceholder="Search"
                                        country={'lk'}
                                        enableSearch={true}
                                        containerClass="yourEmailAddressIn"
                                        placeholder="Enter phone number"
                                        value={reqRequest?.whatsappNumber}
                                        onChange={(value: string, country: any) => {
                                          handleFieldChange('whatsApp', value);
                                          setIsCountryCode(country.countryCode);
                                        }}
                                        {...rest}
                                      />
                                    </div>
                                  </AntForm.Item>
                                )}
                              </Field>
                            </div>
                          </div>
                        </AntForm.Item>
                      )}
                    </Field>
                  )}
                </AntForm>
                {/* <div className="notifyCheckBoxContent">
                  <div>{t('dashboard.YourSelfContainer.selectNotificationChannelText')}</div>
                  <div className="notifyCheckBox">
                    <Field name="channel">
                      {({ input, meta, ...rest }) => (
                        <AntForm.Item
                          name="channel"
                          valuePropName="checked"
                          validateStatus={meta.touched && meta.error ? 'error' : ''}
                          help={meta.touched && meta.error}
                          label=""
                        >
                          <Checkbox defaultChecked disabled>
                            EMAIL
                          </Checkbox>

                          <CheckboxGroup
                            value={reqRequest?.channels}
                            // options={plainOptions}
                            options={
                              domainValidation(universityResourceState)
                                ? plainOptions
                                : plainOptions.filter((option) => option !== 'WHATSAPP')
                            }
                            onChange={(value) => handleFieldChange('channels', value)}
                          ></CheckboxGroup>
                        </AntForm.Item>
                      )}
                    </Field>
                  </div>
                </div> */}
              </Content>
            </form>
          )}
        />
        {universityResourceState?.univeristyConfig?.recaptureEnabled ? (
          <Col lg={15} xs={24} style={{ marginTop: 10 }}>
            <ReCAPTCHA
              sitekey={`${universityResourceState?.univeristyConfig?.googleRecaptchaKey}`}
              onChange={onChangeReCaptcha}
              onExpired={onExpiredReCaptcha}
              type="image"
            />
          </Col>
        ) : (
          <></>
        )}

        <Col lg={15} xs={24} style={{ marginTop: 10 }}>
          <Checkbox className="Checkbox" checked={agreedToTerms} onChange={() => setAgreedToTerms(!agreedToTerms)}>
            <div className="CheckboxText">
              <span> {t('dashboard.SayHelloPopup.checkboxText')} </span>
              <a
                className="termsOfUseText"
                href={process.env.REACT_APP_TERMS_AND_CONDITION_URL}
                rel="noreferrer"
                style={{
                  color: `${universityResourceState?.theme?.themeColor}`,
                }}
                target="_blank"
              >
                {t('dashboard.SayHelloPopup.termsOfUseText')}
              </a>{' '}
              <a
                className="privacyPolicyText"
                href={process.env.REACT_APP_PRIVACY_POLICY_URL}
                rel="noreferrer"
                style={{
                  color: `${universityResourceState?.theme?.themeColor}`,
                }}
                target="_blank"
              >
                {t('dashboard.SayHelloPopup.privacyPolicyText')}
              </a>{' '}
              {t('dashboard.SayHelloPopup.privacyPolicyTextAnd')}{' '}
              <Popover className="popoverLayout" placement="bottomRight" content={content} trigger="click">
                <a
                  className="chatRulesText"
                  href="Chat Rules"
                  style={{
                    color: `${universityResourceState?.theme?.themeColor}`,
                  }}
                >
                  {t('dashboard.SayHelloPopup.chatRulesText')}
                </a>
              </Popover>
            </div>
          </Checkbox>
        </Col>
      </div>
    </>
  );
};

export default memo(YourSelfContainer);
