export type PersonPostsResourceProps = {
    id: string;
  };

  export const getPersonPostsResourceRequest = async (personPostsResourceProps: PersonPostsResourceProps) => {
    // console.log("=== came ===", personPostsResourceProps);
    
    return await fetch(`${process.env.REACT_APP_API_URL}/post/buddy/${personPostsResourceProps.id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
      },
      // body: JSON.stringify({
      //     auth_token:user.auth_token
      // }),
    }).then((res) => res.json());
  };