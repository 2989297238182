import "./PageLoader.scss";

const index = () => {
  return (
    <div className="page-loader">
      <div className="spinner"></div>
    </div>
  );
};

export default index;
